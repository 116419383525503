import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Create, GetAll, Delete, Update, GetByGuid } from '@/services/facturaServices';
import { toast, variant, formatDate, formatPrice } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useFactura = () => {
    var factura = reactive({
        id: null,
        fecha: null,
        idCliente: null,
        idMedico: null,
        concepto: null,
        monto: null,
        idItbis: null,
        idIsr: null,
        total: null,
        deliveredBy: null,
        receivedBy: null,
        createdDate: null
    });

    var isLoading = ref(false);
    var isDeleteLoading = ref(false);
    var deletionDialog = ref({});
    var facturas = ref([]);
    var filter = reactive({
        isOpen: false,
        fecha: null,
        idCliente: null,
        idMedico: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        description: null
    });

    var pagination = ref({});

    const rules = {
        concepto:  { required },
        monto:  { required }
    }

    const validate$ = useVuelidate(rules, factura);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            
            const response = await Create(factura);
    
            if(response.status == httpStatusCode.OK){
                factura.id = response.data.value.id;
                factura.createdDate = response.data.value.createdDate;

                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Creación de factura',
                body: 'No se pudo crear el factura, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getFacturas = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                facturas.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de facturas',
                body: 'No se pudo consultar el listado de facturas, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentFactura = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                factura.id = data.id;
                factura.fecha = data.fecha;
                factura.idCliente = data.idCliente;
                factura.idMedico = data.idMedico;
                factura.concepto = data.concepto;
                factura.monto = data.monto;
                factura.idIsr = data.idIsr;
                factura.idItbis = data.idItbis;
                factura.total = data.total;
                factura.deliveredBy = data.deliveredBy;
                factura.receivedBy = data.receivedBy;
                factura.createdDate = formatDate(data.createdDate);
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de factura',
                body: 'No se pudo consultar el factura, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }
            
            isLoading.value = true;
            const response = await Update(factura);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de factura',
                body: 'No se pudo actualizar la factura, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getFacturas();
        isLoading.value = false;
    }

    const onDeleteHandle = (factura, index) => {
        deletion.index = index;
        deletion.id = factura.id;
        deletion.description = `Estás seguro de quieres eliminar (#${factura.id})?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                facturas.value.splice(deletion.index, 1);
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Facturas',
                body: 'No se pudo consultar el listado de factura, por favor consulte el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        factura,
        isLoading,
        isDeleteLoading,
        facturas,
        filter,
        deletion,
        deletionDialog,
        pagination,
        onCreateHandle,
        getFacturas,
        getCurrentFactura,
        openFilterHandle,
        onUpdateHandle,
        onPaginationHandle,
        onDeleteHandle,
        onDelete,
        formatPrice,
        formatDate
    }
}