import { CORE } from "./commonServices";

/**
 * Create client
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Create = async (model) =>{
    try{
        const result = await CORE.post('ClienteMedico', model);
        return result;
    }catch(error){
        return error.response;
    }
};


/**
 * Delete client
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Delete = async (id) =>{
    try{
        const result = await CORE.delete(`ClienteMedico/${id}`);
        return result;
    }catch(error){
        return error.response;
    }
};
