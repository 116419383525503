export const estatus = {
    Activo: {
        id: 1,
        guid: '4399119c-c4fd-11ee-beaf-9418826e6379'
    },
    Pendiente: {
        id: 2,
        guid: '439915f4-c4fd-11ee-beaf-9418826e6379'
    },
    Completado: {
        id: 3,
        guid: '43991645-c4fd-11ee-beaf-9418826e6379'
    }
}