
import Base from '@/components/MainWrapper.vue'

import ListLote from '@/views/lotes/ListLote.vue';
import CreateLote from '@/views/lotes/CreateLote.vue';
import UpdateLote from '@/views/lotes/UpdateLote.vue';

const lotes = [{
    path: '/lotes',
    component: Base,
    children: 
    [{
        path: '',
        name: 'ListLote',
        component: ListLote,
        meta:{
            title: 'Lotes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'create',
        name: 'CreateLote',
        component: CreateLote,
        meta:{
            title: 'Lotes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'update/:guid',
        name: 'UpdateLote',
        component: UpdateLote,
        meta:{
            title: 'Lotes',
            requiredAuth: true,
            roles: []
        }
    }]
}];

export default lotes;