import { reactive, ref } from 'vue';
import { useAccountStore } from '@/store/accountStore';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { Login } from '@/services/accountServices'
import { httpStatusCode } from '@/helpers/httpStatusCode';
import { toast, variant } from '@/helpers/utility';

export const useAccount = () => {
    const accountStore = useAccountStore();
    const { login, user } = storeToRefs(accountStore);
    const router = useRouter();

    var email = ref(null);
    var isLoading = ref(false);
    var recovery = reactive({
        isValid: null,
        password: null,
        confirmPassword: null
    });


    const onLoginHandle = async () => {
        try {
            isLoading.value = true;
            
            const response = await Login(login.value);
            
            if(response.status == httpStatusCode.OK){
                accountStore.setToken(response);
                router.push({
                    name: 'Menu'
                })
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Sign In',
                body: 'Failed to login in AQS - System, please contact support center'
            });
            throw new Error(error);   
        }
    }

    const onRecoveryHandle = async () => {
        isLoading.value = true;
        await accountStore.recovery(email.value);
        isLoading.value = false;
    }

    const onValidKeyHandle = async (key) => {
        isLoading.value = true;
        recovery.isValid = await accountStore.recoveryValidKey(key);
        isLoading.value = false;
    }

    const onChangeRecoveryHandle = async (guid) => {
        if(recovery.password !== recovery.confirmPassword){
            toast({
                variant: variant.warning,
                title: 'Password',
                body: 'The passwords do not match, they are different'
            });

            return;
        }

        isLoading.value = true;
        const response = await accountStore.recoveryChange(guid, {
            newPassword : recovery.password
        });

        recovery.isValid = !response;
        isLoading.value = false;
    }

    const onLogoutHandle = () => {
        accountStore.sigOut();
        router.push({
            name: 'Login'
        });
    }

    return {
        login,
        email,
        recovery,
        user,
        isLoading,
        onLoginHandle,
        onRecoveryHandle,
        onValidKeyHandle,
        onChangeRecoveryHandle,
        onLogoutHandle
    }
}