<template>
    <div class="input-group-sm mb-1 m-0 p-0">
        <label :for="id" class="form-label input-label" v-if="label">{{ label }}</label>
        <VDatePicker v-model="value">
            <template #default="{ inputValue, inputEvents }">
            <input 
                    :id="id"
                    :value="inputValue" 
                    v-on="inputEvents"  
                    v-bind="$attrs"
                    :placeholder="currentPlaceholder"
                    class="form-control"
                    :class="{
                        'is-invalid': hasError
                    }"  />
            </template>
        </VDatePicker>
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue', 'change']);

const props = defineProps({
    id: null,
    placeholder: null,
    label: null,
    type: null,
    modelValue: {
        type: String,
        default: ""
    },
    errors: {
        type: Array,
        default: () => []
    }
})

const hasError = computed(() => props.errors.length > 0);
const currentPlaceholder = computed(() => {
  return hasError.value && props.errors[0].$message
    ? props.errors[0].$message
    : props.placeholder;
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    emit('change', value);
  }
});
</script>

<style scoped>
.input-label{
    font-size: 0.8em;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.invalid-feedback{
    font-size: 0.8em;
}

.is-invalid::placeholder {
  color: #dc3545;
  opacity: 1;
}
</style>