<template>
    <default-layout>
        <form @submit.stop.prevent="onUpdateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Actualización de centros medicos"
                    description="Complete el formulario y cree un nuevo centro medico"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :text="editingReadOnly ? 'Editar': 'Cancelar'"
                            class="btn btn-warning text-white mx-2"
                            @click="() => editingReadOnly = !editingReadOnly"
                            type="button"
                        />

                        <custom-button 
                            text="Eliminar"
                            class="btn btn-danger text-white mx-2"
                            @click="onDeleteHandle(centro)"
                            type="button"
                            :disabled="editingReadOnly"
                        />

                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                            :disabled="editingReadOnly"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="nombre"
                    placeholder="Nombre"
                    label="Nombre"
                    type="text"
                    v-model="centro.nombre"
                    :errors="validate$.nombre.$errors"
                    :disabled="editingReadOnly" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="identificacion"
                    placeholder="Identificación"
                    label="Identificación"
                    type="text"
                    v-model="centro.identificacion"
                    :errors="validate$.identificacion.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="direccion"
                    placeholder="Dirección"
                    label="Dirección"
                    type="text"
                    v-model="centro.direccion"
                    :errors="validate$.direccion.$errors"
                    :disabled="editingReadOnly"
                />
            </div>
        </form>
        
        <alert-dialog 
                ref="deletionDialog"
                title="Eliminación de centros"
                :description="deletion.description"
                accept-text="Si"
                close-text="No"
                :is-loading="isDeleteLoading"
                @accept="onDelete()" 
        />

        <custom-float-button 
            :options="[{
                route: 'ListCentro',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateCentro',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import AlertDialog from '@/components/modal/AlertDialog.vue'
import { useCentro } from '@/composables/useCentro'


const editingReadOnly = ref(true);

const { 
    validate$,
    centro,
    isLoading,
    deletion,
    deletionDialog,
    isDeleteLoading,
    onUpdateHandle,
    getCurrentCentro,
    onDeleteHandle,
    onDelete,
} = useCentro();

const route = useRoute();

onMounted(async () => {
    await getCurrentCentro(route.params.guid)
})

</script>