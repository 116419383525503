import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { GetAll, Delete, Update, GetByGuid } from '@/services/procedimientoServices';
import { GetByIdentificacion, GetByNss } from '@/services/pacienteServices';
import { Create, Delete as DeleteProcedimiento } from '@/services/procedimientoServices'
import { toast, variant, formatDate, formatPrice } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const  useReclamo = () => {
    const pacienteInitialState = {
        id: null,
        nombre: null,
        idTipoIdentificacion: null,
        identificacion: null,
        idCliente: null,
        sexo: null,
        email: null,
        direccion: null,
        fechaNacimiento: null,
        telefono: null,
        nss: null,
    };

    const procedimientoInitialState = {
        id: null,
        paciente: { ...pacienteInitialState },
        idMedico: null,
        fecha: null,
        descripcion: null,
        noCaso: null,
        noAutorizacion: null,
        detalles: []
    };

    var procedimiento = ref({ ...procedimientoInitialState });

    const procedimientoDetalleInitialState = {
        idProcedimiento: null,
        idTipoProcedimiento: null,
        idGrupoProcedimiento: null,
        grupoProcedimiento: null,
        ptcCobertura: null,
        tipoProcedimiento: null,
        cantidad: null,
        precio: null,
        total: null,
        cobertura: null,
        descuento: null
    }

    var procedimientoDetalle = reactive({ ...procedimientoDetalleInitialState });

    var isLoading = ref(false);
    var isDeleteLoading = ref(false);
    var deletionDialog = ref({});
    var reclamos = ref([]);
    var filter = reactive({
        isOpen: false,
        codigo: null,
        idTipoRnc: null,
        rnc: null,
        nombre: null,
        razon: null,
        email: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        description: null
    });

    var pagination = ref({});

    const rules = {
        paciente: {
            idTipoIdentificacion: { required },
            identificacion: { required },
            idCliente: { required },
            nombre: { required },
            fechaNacimiento: { required },
            sexo: { required },
            nss: { required },
            telefono: { required }
        },
        idMedico: { required },
    }

    const procedimientoDetalleRules = {
        grupoProcedimiento: { 
            id: {
                required 
            }
        },
        tipoProcedimiento: { 
            id: {
                required
            }
        },
        cantidad: {
            required
        },
        precio: {
            required
        }
    }

    const validate$ = useVuelidate(rules, procedimiento);
    const vDetalle$ = useVuelidate(procedimientoDetalleRules, procedimientoDetalle);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;

            const procedimientoPayload = {
                idMedico: procedimiento.value.idMedico,
                descripcion: procedimiento.value.descripcion,
                noAutorizacion: procedimiento.value.noAutorizacion,
                paciente: {
                    ...procedimiento.value.paciente,
                    diagnosticos: [],
                    procedimientos: []
                },
                detalles: procedimiento.value.detalles.map((detalle) => {
                    return {
                        idTipoProcedimiento: detalle.tipoProcedimiento.id,
                        idGrupoProcedimiento: detalle.grupoProcedimiento.id,
                        ptcCobertura: detalle.ptcCobertura,
                        cantidad: detalle.cantidad,
                        precio: detalle.precio,
                        total: detalle.total,
                        cobertura: detalle.cobertura,
                        descuento: detalle.descuento,
                    }
                })
            }
            
            const response = await Create(procedimientoPayload);
    
            if(response.status == httpStatusCode.OK){
                procedimiento.value.id = response.data.value.id;

                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Creación de paciente',
                body: 'No se pudo crear el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getReclamos = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                reclamos.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de reclamos',
                body: 'No se pudo consultar el listado de reclamos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentReclamo = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                console.log(data);
                procedimiento.value = data;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de paciente',
                body: 'No se pudo consultar el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getPacienteByIdentificacion = async (identificacion) => {
        try {
            validate$.value.$reset();

            if(identificacion){
                
                const response = await GetByIdentificacion(identificacion);
    
                if(response.status == httpStatusCode.OK){
                    const { data: { value: data } } = response;
                
                    if(data){
                        procedimiento.value.paciente = data;
                    }else{
                        Object.assign(procedimiento.value.paciente, pacienteInitialState);
                    }
                }else{
                    Object.assign(procedimiento.value.paciente, pacienteInitialState);

                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                }
            }
            
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de paciente',
                body: 'No se pudo consultar el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getPacienteByNss = async () => {
        try {
            validate$.value.$reset();

            if(procedimiento.value.paciente.nss){
                const response = await GetByNss(procedimiento.value.paciente.nss);
    
                if(response.status == httpStatusCode.OK){
                    const { data: { value: data } } = response;
                
                    if(data){
                        procedimiento.value.paciente = data;
                    }else{
                        Object.assign(procedimiento.value.paciente, pacienteInitialState);
                    }
                }else{
                    Object.assign(procedimiento.value.paciente, pacienteInitialState);
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                }
            }
            
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de paciente',
                body: 'No se pudo consultar el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }
            
            isLoading.value = true;

            const response = await Update(procedimiento);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de procedimiento',
                body: 'No se pudo actualizar el procedimiento, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getReclamos();
        isLoading.value = false;
    }

    const onDeleteHandle = (reclamo, index) => {
        deletion.index = index;
        deletion.id = reclamo.id;
        deletion.description = `Estás seguro de quieres eliminar (#${reclamo.id} ${reclamo.nombre})?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                reclamos.value.splice(deletion.index, 1);
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Pacientes',
                body: 'No se pudo consultar el listado de reclamos, por favor consulte el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const addDetalleHandle = async () => {
        const isValid = await vDetalle$.value.$validate();
        if(!isValid){
            return;
        }

        let total = ((procedimientoDetalle.cantidad * procedimientoDetalle.precio) - procedimientoDetalle.descuento);
        let cobertura = (total * ((procedimientoDetalle.ptcCobertura ?? 0)/100));
        let diferencia = (total - cobertura);

        procedimiento.value.detalles.push({
            ...procedimientoDetalle,
            total: total,
            cobertura: cobertura,
            descuento: procedimientoDetalle.descuento,
            diferencia: diferencia
        });

        procedimientoDetalle.idProcedimiento = null;
        procedimientoDetalle.idTipoProcedimiento = null;
        procedimientoDetalle.tipoProcedimiento = null;
        procedimientoDetalle.grupoProcedimiento = null;
        procedimientoDetalle.cantidad = null;
        procedimientoDetalle.precio = null;
        procedimientoDetalle.total = null;
        procedimientoDetalle.cobertura = null;
        procedimientoDetalle.ptcCobertura = null;
        procedimientoDetalle.descuento = null;

        vDetalle$.value.$reset();
    };

    const deleteProcedimientoHandle = async (proce, index) => {
        try {
            if(procedimiento.value.paciente.id){
                const response = await DeleteProcedimiento(proce.id);
        
                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                     
                    return;
                }
            } 

            procedimiento.value.paciente.procedimientos.splice(index, 1);
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Procedimiento',
                body: 'No se pudo eliminar el procedimiento, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error); 
        }
    }

    const deleteProcedimientoDetalleHandle = (proce, index) => procedimiento.value.detalles.splice(index, 1);

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        vDetalle$,
        procedimientoDetalle,
        isLoading,
        isDeleteLoading,
        reclamos,
        filter,
        procedimiento,
        deletion,
        deletionDialog,
        pagination,
        onCreateHandle,
        getReclamos,
        getCurrentReclamo,
        getPacienteByIdentificacion,
        getPacienteByNss,
        openFilterHandle,
        onUpdateHandle,
        onPaginationHandle,
        onDeleteHandle,
        onDelete,
        addDetalleHandle,
        deleteProcedimientoHandle,
        deleteProcedimientoDetalleHandle,
        formatPrice,
        formatDate
    }
}