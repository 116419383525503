<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de asistente"
                    description="Complete el formulario y cree un nuevo asistente"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="nombre"
                    placeholder="Nombre"
                    label="Nombre"
                    type="text"
                    v-model="asistente.nombre"
                    :errors="validate$.nombre.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="apellido"
                    placeholder="Apellido"
                    label="Apellido"
                    type="text"
                    v-model="asistente.apellido"
                    :errors="validate$.apellido.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="identificacion"
                    placeholder="Identificación"
                    label="Identificación"
                    type="text"
                    v-model="asistente.identificacion"
                    :errors="validate$.identificacion.$errors" 
                />
            </div>
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListAsistente',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateAsistente',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import { useAsistente } from '@/composables/useAsistente'

const {
    validate$,
    isLoading,
    asistente,
    onCreateHandle
} = useAsistente();
</script>