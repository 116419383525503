import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router';
import { Create, GetAll, Delete, Update, GetByGuid } from '@/services/medicoServices';
import { Create as CreateEspecialidad, Delete as DeleteEspecialidad } from '@/services/especialidadMedicoServices'
import { Create as CreateCentro, Delete as DeleteCentro } from '@/services/centroMedicoServices';
import { Create as CreateComprobante, Delete as DeleteComprobante } from '@/services/comprobanteServices';
import { Create as CreateCliente, Delete as DeleteCliente } from '@/services/clienteMedicoServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';


export const useMedico = () => {
    const router = useRouter();

    const medicoInitialState = {
        id: null,
        codigo: null,
        nombre: null,
        apellido: null,
        idTipoIdentificacion: null,
        identificacion: null,
        direccion: null,
        exequatur: null,
        noColegio: null,
        telefono: null,
        email: null,
        photo: null,
        usuarioDgii: null,
        passwordDgii: null,
        targetaDgii: null,
        especialidades: [],
        centros: [],
        comprobantes: [],
        clientes: []
    };

    var medico = ref({ ...medicoInitialState });

    const especialidadInitialState = {
        id: null,
        idEspecialidad: null,
        especialidad: null
    };

    var especialidad = reactive({ ...especialidadInitialState });

    const centroInitialState = {
        id: null,
        idMedico: null,
        idCentro: null,
        centro: null,
        idTanda: null,
        tanda: null,
        idAsistente: null
    }

    var centro = reactive({ ...centroInitialState });


    const comprobanteInitialState = {
        id: null,
        idTipoComprobante: null,
        tipoComprobante: null,
        inicio: null,
        fin: null,
        fechaVence: null
    }

    const comprobante = reactive({ ...comprobanteInitialState });

    const clienteInitialState = {
        id: null,
        idCliente: null,
        codigo: null,
    };

    const cliente = reactive({ ...clienteInitialState });

    var isLoading = ref(false);
    var isEspecialidadCreateLoading = ref(false);
    var isAsistenteCreateLoading = ref(false);
    var isCentroCreateLoading = ref(false);
    var isComprobanteCreateLoading = ref(false);
    var isDeleteLoading = ref(false);
    var isClienteCreateLoading = ref(false);
    var deletionDialog = ref({});
    var medicos = ref([]);
    var filter = reactive({
        isOpen: false,
        codigo: null,
        idTipoIdentificacion: null,
        identificacion: null,
        nombre: null,
        exequatur: null,
        noColegio: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        description: null
    });

    var pagination = ref({});

    const rules = {
        nombre: { required },
        apellido: { required },
        idTipoIdentificacion: { required },
        identificacion: { required },
        direccion: { required },
        noColegio: { required },
        exequatur: { required },
        telefono: { required },
        email: { required },
    }

    const especialidadRules = {
        idEspecialidad: { required }
    }

    const centroRules = {
        idCentro: {required },
        idTanda: { required },
        idAsistente: {required }
    }

    const comprobanteRules = {
        idTipoComprobante: { required },
        inicio: { required },
        fin: { required },
        fechaVence: { required }
    }

    const clienteRules = {
        idCliente: { required },
        codigo: { required }
    };

    const validate$ = useVuelidate(rules, medico);
    const vEspecialidad$ = useVuelidate(especialidadRules, especialidad);
    const vCentro$ = useVuelidate(centroRules, centro);
    const vComprobante$ = useVuelidate(comprobanteRules, comprobante);
    const vCliente$ = useVuelidate(clienteRules, cliente);


    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            
            var medicoPayLoad = {
                ...medico.value,
                especialidades: medico.value.especialidades.map((espec) => {
                    return {
                        idEspecialidad: espec.idEspecialidad
                    }
                }),
                centros: medico.value.centros.map((cent) => {
                    return {
                        idCentro: cent.idCentro,
                        idTanda: cent.idTanda,
                        idAsistente: cent.idAsistente,
                    }
                }),
                comprobantes: medico.value.comprobantes.map((comp) => {
                    return {
                        fin: comp.fin,
                        inicio: comp.inicio,
                        idTipoComprobante: comp.idTipoComprobante,
                        fechaVence: comp.fechaVence
                    }
                }),
                clientes: medico.value.clientes.map((client) => {
                    return {
                        idCliente: client.idCliente,
                        codigo: client.codigo,
                    }
                })
            }
            
            const response = await Create(medicoPayLoad);
    
            if(response.status == httpStatusCode.OK){
                if(!medico.value.id){
                    Object.assign(medico.value, medicoInitialState);
                    medico.value.especialidades = [];
                    medico.value.centros = [];
                    medico.value.comprobantes = [];
                    medico.value.clientes = [];
                }
                
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                validate$.value.$reset();
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Creación de médico',
                body: 'No se pudo crear el médico, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getMedicos = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                medicos.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de médicos',
                body: 'No se pudo consultar el listado de médicos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentMedico = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                medico.value = data;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de médico',
                body: 'No se pudo consultar el médico, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }
            
            isLoading.value = true;
            const response = await Update(medico);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de médico',
                body: 'No se pudo actualizar el médico, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getMedicos();
        isLoading.value = false;
    }

    const addEspecialidadHandle = async () => {
        try {
            const isValid = await vEspecialidad$.value.$validate();
            if(!isValid){
                return;
            }
    
            isEspecialidadCreateLoading.value = true;
    
            if(medico.value.id){
                const response = await CreateEspecialidad({
                    idMedico: medico.value.id,
                    idEspecialidad: especialidad.idEspecialidad
                });
        
                if(response.status == httpStatusCode.OK){
                    especialidad.id = response.data.value.id;

                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                     
                    return;
                }
            }
    
            medico.value.especialidades.push({
                ...especialidad
            })
    
            Object.assign(especialidad, especialidadInitialState);
            vEspecialidad$.value.$reset();
    
            isEspecialidadCreateLoading.value = false;   
        } catch (error) {
            isEspecialidadCreateLoading.value = false;

            toast({
                variant: variant.error,
                title: "Especialidad",
                body:  "No se pudo agregar la especialidad, consulte con el centro de soporte"
            });

            throw new Error(error);
        }
    }

    const addCentroHandle = async () => {
        try {
            const isValid = await vCentro$.value.$validate();
            if(!isValid){
                return;
            }

            isCentroCreateLoading.value = true;

            if(medico.value.id){
                const response = await CreateCentro({
                    idMedico: medico.value.id,
                    idCentro: centro.idCentro,
                    idTanda: centro.idTanda,
                    idAsistente: centro.idAsistente
                });
        
                if(response.status == httpStatusCode.OK){
                    centro.id = response.data.value.id;

                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    isCentroCreateLoading.value = false;

                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                     
                    return;
                }
            }
    
            medico.value.centros.push({
                ...centro
            })
    
            Object.assign(centro, centroInitialState);
            vCentro$.value.$reset(); 
            isCentroCreateLoading.value = false;
        } catch (error) {
            isCentroCreateLoading.value = false;

            toast({
                variant: variant.error,
                title: "Centro médico",
                body:  "No se pudo agregar el centro médico, consulte con el centro de soporte"
            });

            throw new Error(error);
        }
    }

    const addComprobanteHandle = async () => {
        try {
            const isValid = await vComprobante$.value.$validate();
            if(!isValid){
                return;
            }

            isComprobanteCreateLoading.value = true;

            if(medico.value.id){
                const response = await CreateComprobante({
                    idMedico: medico.value.id,
                    fin: comprobante.fin,
                    inicio: comprobante.inicio,
                    idTipoComprobante: comprobante.idTipoComprobante,
                    fechaVence: comprobante.fechaVence
                });
        
                if(response.status == httpStatusCode.OK){
                    comprobante.id = response.data.value.id;

                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    isComprobanteCreateLoading.value = false;

                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                    
                    return;
                }
            }

            medico.value.comprobantes.push({
                ...comprobante
            })

            Object.assign(comprobante, comprobanteInitialState);
            vComprobante$.value.$reset();   

            isComprobanteCreateLoading.value = false;
        } catch (error) {
            isComprobanteCreateLoading.value = false;

            toast({
                variant: variant.error,
                title: "Comprobante",
                body:  "No se pudo agregar el el comprobante, consulte con el centro de soporte"
            });

            throw new Error(error);
        }
    }

    const addClienteHandle = async () => {
        try {
            const isValid = await vCliente$.value.$validate();
            if(!isValid){
                return;
            }

            isClienteCreateLoading.value = true;

            if(medico.value.id){
                const response = await CreateCliente({
                    idMedico: medico.value.id,
                    idCliente: cliente.idCliente,
                    codigo: cliente.codigo
                });
        
                if(response.status == httpStatusCode.OK){
                    cliente.id = response.data.value.id;

                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    isClienteCreateLoading.value = false;

                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                    
                    return;
                }
            }

            medico.value.clientes.push({
                ...cliente
            })

            Object.assign(cliente, clienteInitialState);
            vCliente$.value.$reset();   

            isClienteCreateLoading.value = false;
        } catch (error) {
            isClienteCreateLoading.value = false;

            toast({
                variant: variant.error,
                title: "Cliente",
                body:  "No se pudo agregar el cliente, consulte con el centro de soporte"
            });

            throw new Error(error);
        }
    };

    const deleteEspecialidadHandle = async (especialidad, index) => {
        
        if(medico.value.id){
            const response = await DeleteEspecialidad(especialidad.id);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
                 
                return;
            }
        }        

        medico.value.especialidades.splice(index, 1);
    }

    const deleteCentroHandle = async (centro, index) => {
        if(medico.value.id){
            const response = await DeleteCentro(centro.id);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
                 
                return;
            }
        }        

        medico.value.centros.splice(index, 1)
    };
    
    const deleteComprobanteHandle = async (comprobante, index) => {
        if(comprobante.id){
            const response = await DeleteComprobante(comprobante.id);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
                 
                return;
            }
        }   

        medico.value.comprobantes.splice(index, 1);
    } 

    const deleteClienteHandle = async (cliente, index) => {
        if(medico.value.id){
            const response = await DeleteCliente(cliente.id);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
                 
                return;
            }
        }        

        medico.value.clientes.splice(index, 1);
    }

    const onDeleteHandle = (medico) => {
        deletion.id = medico.id;
        deletion.description = `Estás seguro de quieres eliminar (#${medico.id} ${medico.nombre})?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({ name: 'ListMedico' });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Médico',
                body: 'No se pudo eliminar el médico, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;

    return {
        validate$,
        vEspecialidad$,
        vCentro$,
        vComprobante$,
        vCliente$,
        medico,
        especialidad,
        centro,
        comprobante,
        cliente,
        isLoading,
        isDeleteLoading,
        isEspecialidadCreateLoading,
        isAsistenteCreateLoading,
        isComprobanteCreateLoading,
        isCentroCreateLoading,
        medicos,
        filter,
        deletion,
        deletionDialog,
        pagination,
        onCreateHandle,
        getMedicos,
        getCurrentMedico,
        openFilterHandle,
        onUpdateHandle,
        onPaginationHandle,
        addEspecialidadHandle,
        addCentroHandle,
        addComprobanteHandle,
        addClienteHandle,
        deleteEspecialidadHandle,
        deleteCentroHandle,
        deleteComprobanteHandle,
        deleteClienteHandle,
        onDeleteHandle,
        onDelete,
        formatDate
    }
}