import { CORE } from "../commonServices";
import { useAccountStore } from '@/store/accountStore';

const setup = () => {
    const accountStore = useAccountStore();
    
    CORE.interceptors.request.use(
      (config) => {
        const token = accountStore.getAccessToken;

        if (token) {
            const bearerAuthorization = `Bearer ${token}`
            config.headers["Authorization"] = bearerAuthorization;
            CORE.defaults.headers.Authorization = bearerAuthorization;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    CORE.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
  
        if (originalConfig.url !== "/account/login" && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
  
            try {
              const rs = await CORE.post("account/token/refresh", {
                accessToken: accountStore.getAccessToken,
                refreshToken: accountStore.getRefreshToken,
              });

              const { token, refreshToken } = rs.data;
              accountStore.updateTokes(token, refreshToken);
              return CORE(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
  
        return Promise.reject(err);
      }
    );
};

export default setup;