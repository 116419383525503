<template>
    <div class="input-group-sm mb-1 m-0 p-0">
        <label :for="id" class="form-label input-label" v-if="label">{{ label }}</label>
        <input 
            :id="id"
            :value="modelValue" 
            :type="type" 
            :placeholder="currentPlaceholder"
            @input="$emit('update:modelValue', $event.target.value)"
            class="form-control"
            :class="{
                'is-invalid': hasError
            }" 
            v-bind="$attrs">
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
    id: null,
    placeholder: null,
    label: null,
    type: null,
    modelValue: {
        type: String,
        default: ""
    },
    errors: {
        type: Array,
        default: () => []
    }
})

const hasError = computed(() => props.errors.length > 0);
const currentPlaceholder = computed(() => {
  return hasError.value && props.errors[0].$message
    ? props.errors[0].$message
    : props.placeholder;
});
</script>

<style scoped>
.input-label{
    font-size: 0.8em;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.invalid-feedback{
    font-size: 0.8em;
}

.is-invalid::placeholder {
  color: #dc3545;
  opacity: 1;
}
</style>