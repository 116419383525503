<template>
    <default-layout>
        <section>
            <div>
                <control-section-title 
                    title="Reclamos"
                    description="Listado de reclamos creados"
                    icon="fas fa-user-injured">
                    <template v-slot:control>
                        <custom-button 
                            type="button"
                            text="Filtros"
                            class="btn btn-primary"
                            @click="openFilterHandle()"
                            icon="fas fa-filter fa-sm"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div>
                <paginate-table
                    :pagination="pagination"
                    :headers="[
                            'No. Caso', 
                            'Paciente', 
                            'Nss', 
                            'ARS',
                            'Medico',
                            'Autorización',
                            'Fecha Creación']"
                    :is-loading="isLoading"
                    :is-empty="reclamos.length"
                    empty-message="No hay reclamos para mostrar"
                    @pageChange="onPaginationHandle($event)">
                    
                    <template v-slot:tr>
                        <tr 
                                @click="route.push({
                                    name: 'UpdateReclamo', 
                                    params: { guid: procedimiento.guid }
                                })"
                                v-for="procedimiento in reclamos" :key="procedimiento.id"
                                class="cursor-pointer">
                            <th scope="row">{{ procedimiento.id }}</th>
                            <td>{{ procedimiento.paciente.nombre }}</td>
                            <td>{{ procedimiento.paciente.nss }}</td>
                            <td>{{ procedimiento.paciente.cliente?.nombre }}</td>
                            <td>{{ procedimiento.medico?.nombre }}</td>
                            <td>{{ procedimiento.noAutorizacion }}</td>
                            <td>{{ formatDate(procedimiento.createdDate) }}</td>
                        </tr>
                    </template>
                </paginate-table>
            </div>
        </section>

        <custom-float-button 
            :options="[{
                route: 'ListReclamo',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateReclamo',
                icon: 'fas fa-plus'
            }]"
        />

        <side-nav 
                title="Filters" 
                :is-open="filter.isOpen" 
                @cancel="openFilterHandle">
            
                <form @submit.stop.prevent="getReclamos" class="row">
                    <div class="col-12">
                        <custom-input
                            id="fechaDesde"
                            placeholder="Fecha Desde"
                            label="Fecha Desde"
                            type="date"
                            v-model="filter.dateFrom"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaHasta"
                            placeholder="Fecha Hasta"
                            label="Fecha Hasta"
                            type="date"
                            v-model="filter.dateTo"
                        />
                    </div>

                    <div class="col-12">
                        <custom-button 
                            type="submit"
                            text="Filtrar"
                            class="btn btn-primary float-end"
                            :is-loading="isLoading"
                        />
                    </div>
                </form>
        </side-nav>
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import SideNav from '@/components/SideNav.vue'
import PaginateTable from '@/components/table/PaginateTable.vue'
import { useReclamo } from '@/composables/useReclamo'


const route = useRouter();

const {
    reclamos,
    pagination,
    isLoading,
    filter,
    getReclamos,
    openFilterHandle,
    onPaginationHandle,
    formatDate,
} = useReclamo();


onMounted(async () => {
    await getReclamos();
});
</script>