<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de metodo de pago"
                    description="Complete el formulario y cree un nuevo metodo de pago"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="nombre"
                    placeholder="Nombre"
                    label="Nombre"
                    type="text"
                    v-model="metodoPago.nombre"
                    :errors="validate$.nombre.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="descripcion"
                    placeholder="Descripción"
                    label="Descripción"
                    type="text"
                    v-model="metodoPago.descripcion"
                />
            </div>
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListMetodoPago',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateMetodoPago',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import { useMetodoPago } from '@/composables/useMetodoPago'

const {
    validate$,
    isLoading,
    metodoPago,
    onCreateHandle
} = useMetodoPago();
</script>