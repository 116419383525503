
import Base from '@/components/MainWrapper.vue'

import ListReclamo from '@/views/reclamos/ListReclamo.vue';
import CreateReclamo from '@/views/reclamos/CreateReclamo.vue';
import UpdateReclamo from '@/views/reclamos/UpdateReclamo.vue';

const reclamos = [{
    path: '/reclamos',
    component: Base,
    children: 
    [{
        path: '',
        name: 'ListReclamo',
        component: ListReclamo,
        meta:{
            title: 'Reclamos',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'create',
        name: 'CreateReclamo',
        component: CreateReclamo,
        meta:{
            title: 'Reclamos',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'update/:guid',
        name: 'UpdateReclamo',
        component: UpdateReclamo,
        meta:{
            title: 'Reclamos',
            requiredAuth: true,
            roles: []
        }
    }]
}];

export default reclamos;