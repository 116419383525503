import { CORE } from "./commonServices";

/**
 * List of factura
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const GetAll = async (filter) =>{
    try{
        const result = await CORE.get('Factura', {
            params: filter
        });
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Get factura by guid
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {String}
 * @return {Object|Array}
 **/
export const GetByGuid = async (guid) =>{
    try{
        const result = await CORE.get(`Factura/${guid}`);
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Create factura
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Create = async (model) =>{
    try{
        const result = await CORE.post('Factura', model);
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Update factura
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Update = async (model) =>{
    try{
        const result = await CORE.put('Factura', model);
        return result;
    }catch(error){
        return error.response;
    }
};


/**
 * Delete factura
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Delete = async (id) =>{
    try{
        const result = await CORE.delete(`Factura/${id}`);
        return result;
    }catch(error){
        return error.response;
    }
};