<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                            title="Creación de factura"
                            description="Complete el formulario y cree una factura"
                            icon="fas fa-receipt">
                            <template v-slot:control>
                                <custom-button 
                                    :is-loading="isLoading"
                                    text="Crear Factura"
                                    class="btn btn-primary"
                                />
                            </template>        
                </control-section-title>
            </div>
            <div class="col-7">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-input
                            id="no"
                            placeholder="No. Factura"
                            label="No. Factura"
                            type="number"
                            v-model="factura.id"
                            disabled
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-input
                            id="ncf"
                            placeholder="Fecha Factura"
                            label="Fecha Factura"
                            type="text"
                            v-model="factura.createdDate"
                            disabled
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="medico"
                            label="Médico"
                            v-model="factura.idMedico"
                            :data="medicos"
                            :properties="{
                                    key: 'id',
                                    name: 'nombre',
                                    value: 'id'
                            }"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="cliente"
                            label="Clientes"
                            v-model="factura.idCliente"
                            :data="clientes"
                            :properties="{
                                    key: 'id',
                                    name: 'nombre',
                                    value: 'id'
                            }"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                        <custom-input
                            id="concepto"
                            placeholder="Concepto"
                            label="Concepto"
                            type="text"
                            v-model="factura.concepto"
                            :errors="validate$.concepto.$errors" 
                        />
                    </div>
                    <div class="col-6"></div>
                    <div class="col-6">
                        <custom-input
                            id="monto"
                            placeholder="Monto"
                            label="Monto"
                            type="number"
                            v-model="factura.monto"
                            :errors="validate$.monto.$errors" 
                        />

                        <custom-select 
                            id="itbs"
                            label="ITBIS"
                            v-model="factura.idItbis"
                            :data="itbis"
                            :properties="{
                                    key: 'id',
                                    name: 'nombre',
                                    value: 'id'
                            }"
                        />

                        <custom-select 
                            id="ISR"
                            label="ISR"
                            v-model="factura.idIsr"
                            :data="isrs"
                            :properties="{
                                    key: 'id',
                                    name: 'nombre',
                                    value: 'id'
                            }"
                        />
                    </div>
                </div>
            </div>
            <div class="col-5">

            </div>
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListFactura',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateFactura',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import { useFactura } from '@/composables/useFactura'
import { useCliente } from '@/composables/useCliente'
import { useMedico } from '@/composables/useMedico'
import { useIsr } from '@/composables/useIsr'
import { useItbis } from '@/composables/useItbis'


const { 
        validate$, 
        factura, 
        isLoading, 
        onCreateHandle
    } = useFactura();
const { clientes, getClientes } = useCliente();
const { medicos, getMedicos } = useMedico();
const { itbis, getItbis } = useItbis();
const { isrs, getIsrs } = useIsr();

onMounted(async () => {
    await getMedicos();
    await getClientes();
    await getItbis();
    await getIsrs();
});

</script>