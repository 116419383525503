
import Base from '@/components/MainWrapper.vue'

import ListMedico from '@/views/medicos/ListMedico.vue';
import CreateMedico from '@/views/medicos/CreateMedico.vue';
import UpdateMedico from '@/views/medicos/UpdateMedico.vue';

const medicos = [{
    path: '/medicos',
    component: Base,
    children: 
    [{
        path: '',
        name: 'ListMedico',
        component: ListMedico,
        meta:{
            title: 'Medicos',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'create',
        name: 'CreateMedico',
        component: CreateMedico,
        meta:{
            title: 'Medicos',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'update/:guid',
        name: 'UpdateMedico',
        component: UpdateMedico,
        meta:{
            title: 'Clientes',
            requiredAuth: true,
            roles: []
        }
    }]
}];

export default medicos;