import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Create, GetAll, Delete, Update, GetByGuid, GetNcf } from '@/services/loteServices';
import { GetAll as GetProcedimientos } from '@/services/procedimientoServices';
import { toast, variant, formatDate, formatMoney } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';
import { useRouter } from 'vue-router';

export const useLote = () => {

    const router = useRouter();

    var lote = reactive({
        id: null,
        no: null,
        ncf: null,
        fechaComprobante: null,
        idMedico: null,
        idCliente: null,
        fechaDesde: null,
        fechaHasta: null,
        cantidad: null,
        total: null,
        detalles: [],
        createdDate: null
    });

    var isLoading = ref(false);
    var isNcfLoading = ref(false);
    var isProcedimientoLoading = ref(false);
    var isDeleteLoading = ref(false);
    var deletionDialog = ref({});
    var lotes = ref([]);

    var filter = reactive({
        isOpen: false,
        no: null,
        ncf: null,
        idMedico: null,
        idCliente: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        description: null
    });

    var pagination = ref({});

    const rules = {
        idMedico: { required },
        idCliente: { required }
    }


    const validate$ = useVuelidate(rules, lote);

    

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            
            var lotePayLoad = {
                ...lote,
                detalles: lote.detalles.filter((dt) => dt.checked).map((detalle) => {
                    return {
                        idProcedimiento: detalle.id
                    }
                })
            }
            
            const response = await Create(lotePayLoad);
    
            if(response.status == httpStatusCode.OK){
                lote.id = response.data.value.id;
                lote.createdDate = formatDate(response.data.value.createdDate);
                
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({
                    name: 'UpdateLote',
                    params: {
                        guid: response.data.value.guid
                    }
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Creación de médico',
                body: 'No se pudo crear el médico, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getLotes = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                lotes.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de lotes',
                body: 'No se pudo consultar el listado de lotes, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentLote = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                lote.id = data.id;
                lote.fechaDesde = data.fechaDesde;
                lote.fechaHasta = data.fechaHasta;
                lote.no = data.no;
                lote.ncf = data.ncf;
                lote.fechaComprobante = data.fechaComprobante;
                lote.idMedico = data.idMedico;
                lote.idCliente = data.idCliente;
                lote.cantidad = data.cantidad;
                lote.total = data.total;
                lote.detalles = data.detalles;
                lote.createdDate = data.createdDate;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de lote',
                body: 'No se pudo consultar el lote, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getLoteNcf = async (idLote) => {
        try {
            isNcfLoading.value = true;
            const response = await GetNcf(idLote);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                lote.id = data.id;
                lote.fechaDesde = data.fechaDesde;
                lote.fechaHasta = data.fechaHasta;
                lote.fechaComprobante = formatDate(data.fechaComprobante);
                lote.ncf = data.ncf;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isNcfLoading.value = false;
        } catch (error) {
            isNcfLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Consulta de lote',
                body: 'No se pudo consultar el lote, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getProcedimientos = async () => {
        try {

            isProcedimientoLoading.value = true;

            const response = await GetProcedimientos({
                dateFrom: lote.fechaDesde,
                dateTo: lote.fechaHasta,
                idMedico: lote.idMedico,
                idCliente: lote.idCliente
            });
    
            if(response.status == httpStatusCode.OK){
                lote.detalles =  response.data.value.map((detalle) => {
                    return {
                        ...detalle,
                        checked: false
                    }
                });
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isProcedimientoLoading.value = false;
        } catch (error) {
            isProcedimientoLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Consulta de procedimientos',
                body: 'No se pudo consultar el listado de procedimientos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }
            
            isLoading.value = true;
            const response = await Update(lote);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de lote',
                body: 'No se pudo actualizar el lote, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getLotes();
        isLoading.value = false;
    }

    const onDeleteHandle = (lote, index) => {
        deletion.index = index;
        deletion.id = lote.id;
        deletion.description = `Estás seguro de quieres eliminar (#${lote.id} ${lote.ncf})?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                lotes.value.splice(deletion.index, 1);
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Lote',
                body: 'No se pudo eliminar el lote, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        lote,
        isLoading,
        isDeleteLoading,
        isProcedimientoLoading,
        isNcfLoading,
        lotes,
        filter,
        deletion,
        deletionDialog,
        pagination,
        onCreateHandle,
        formatMoney,
        getLotes,
        getLoteNcf,
        getProcedimientos,
        getCurrentLote,
        openFilterHandle,
        onUpdateHandle,
        onPaginationHandle,
        onDeleteHandle,
        onDelete,
        formatDate
    }
}