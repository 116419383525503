
import Base from '@/components/MainWrapper.vue'

import ListFactura from '@/views/facturas/ListFactura.vue';
import CreateFactura from '@/views/facturas/CreateFactura.vue';
import UpdateFactura from '@/views/facturas/UpdateFactura.vue';

const facturas = [{
    path: '/facturas',
    component: Base,
    children: 
    [{
        path: '',
        name: 'ListFactura',
        component: ListFactura,
        meta:{
            title: 'Facturas',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'create',
        name: 'CreateFactura',
        component: CreateFactura,
        meta:{
            title: 'Facturas',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'update/:guid',
        name: 'UpdateFactura',
        component: UpdateFactura,
        meta:{
            title: 'Facturas',
            requiredAuth: true,
            roles: []
        }
    }]
}];

export default facturas;