<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de lote"
                    description="Complete el formulario y cree un nuevo lote"
                    icon="fas fa-list">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Crear Lote"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                    id="no"
                    placeholder="No. Lote"
                    label="No. Lote"
                    type="number"
                    v-model="lote.id"
                    disabled
                />
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                    id="ncf"
                    placeholder="NCF"
                    label="NCF"
                    type="text"
                    v-model="lote.ncf"
                    disabled
                />
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                    id="ncf"
                    placeholder="Fecha Comprobante"
                    label="Fecha Comprobante"
                    type="text"
                    v-model="lote.fechaComprobante"
                    disabled
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-select 
                    id="medico"
                    label="Médico"
                    v-model="lote.idMedico"
                    :errors="validate$.idMedico.$errors" 
                    :data="medicos"
                    :properties="{
                            key: 'id',
                            name: 'nombre',
                            value: 'id'
                    }"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-select 
                    id="cliente"
                    label="Clientes"
                    v-model="lote.idCliente"
                    :errors="validate$.idCliente.$errors" 
                    :data="clientes"
                    :properties="{
                            key: 'id',
                            name: 'nombre',
                            value: 'id'
                    }"
                />
            </div>

            <div class="col-12">
                <custom-button 
                            type="button"
                            :is-loading="isNcfLoading"
                            text="Generar NCF"
                            class="btn btn-primary float-end mb-3"
                            :disabled="lote.ncf || !lote.id"
                            @click="getLoteNcf(lote.id)"
                /> 
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                    id="fechaDesde"
                    placeholder="Fecha Desde"
                    label="Fecha Desde"
                    type="date"
                    v-model="lote.fechaDesde"
                    :disabled="!lote.idMedico"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                    id="fechaHasta"
                    placeholder="Fecha Hasta"
                    label="Fecha Hasta"
                    type="date"
                    v-model="lote.fechaHasta"
                    :disabled="!lote.idMedico"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <custom-input
                    id="fechaLote"
                    placeholder="Fecha Lote"
                    label="Fecha Lote"
                    type="text"
                    v-model="lote.createdDate"
                    disabled
                />
            </div>

            <div class="col-12">
                <custom-button 
                            :is-loading="isProcedimientoLoading"
                            text="Consultar"
                            class="btn btn-primary float-end mb-3"
                            :disabled="!lote.idMedico || !lote.idCliente"
                            @click="getProcedimientos()"
                            type="button"
                />
            </div>


            <div class="col-12 col-md-12 col-xl-12 col-lg-12">
                <section-title 
                        title="Lote detalle"
                        description="Listado de procedimientos seleccionados en el lote"
                        icon="fas fa-list" 
                />
                <single-table
                        :headers="['#',
                            '',
                            'Caso',
                            'Nombre',
                            'ARS',
                            'NSS',
                            'Autorización',
                            'Monto',
                            '']"
                            :is-loading="false"
                            :is-empty="lote.detalles.length"
                            empty-message="No hay datos de lotes">
                            
                            <template v-slot:tr>
                                <tr v-for="(detal, index) in lote.detalles" :key="index">
                                    <th scope="row">{{ (index + 1) }}</th>
                                    <td>
                                        <input type="checkbox" v-model="detal.checked" />
                                    </td>
                                    <td>{{ detal.noCaso }}</td>
                                    <td>{{ detal.paciente.nombre }} - ({{ detal.paciente.identificacion }})</td>
                                    <td>{{ detal.paciente.cliente.nombre }} - ({{ detal.paciente.cliente.rnc }})</td>
                                    <td>{{ detal.paciente.nss }}</td>
                                    <td>{{ detal.noAutorizacion }}</td>
                                    <td>{{ formatMoney(detal.total) }}</td>
                                </tr>
                            </template>
                </single-table>
            </div>
        </form>

        <custom-float-button 
            :options="[{
                route: 'ListLote',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateLote',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import SingleTable from '@/components/table/SingleTable.vue'
import { useMedico } from '@/composables/useMedico'
import { useCliente } from '@/composables/useCliente'
import { useLote } from '@/composables/useLote'

const { 
    medicos,
    getMedicos,
} = useMedico();


const { 
        validate$, 
        lote, 
        isLoading, 
        isProcedimientoLoading, 
        isNcfLoading, 
        getLoteNcf, 
        getProcedimientos, 
        onCreateHandle,
        formatMoney 
    } = useLote();
const { clientes, getClientes } = useCliente();

onMounted(async () => {
    await getMedicos();
    await getClientes();
});
</script>