
import Base from '@/components/MainWrapper.vue'
//import { roles } from '@/helpers/enums/roles'

import ListCliente from '@/views/clientes/ListCliente.vue';
import CreateCliente from '@/views/clientes/CreateCliente.vue';
import UpdateCliente from '@/views/clientes/UpdateCliente.vue';

const clientes = [{
    path: '/clientes',
    component: Base,
    children: 
    [{
        path: '',
        name: 'ListCliente',
        component: ListCliente,
        meta:{
            title: 'Clientes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'create',
        name: 'CreateCliente',
        component: CreateCliente,
        meta:{
            title: 'Clientes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'update/:guid',
        name: 'UpdateCliente',
        component: UpdateCliente,
        meta:{
            title: 'Clientes',
            requiredAuth: true,
            roles: []
        }
    },]
}];

export default clientes;