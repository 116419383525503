import { reactive, ref } from 'vue';
import { GetAll, GetByGuid } from '@/services/tandaServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useTanda = () => {
    var tanda = reactive({
        id: null,
        nombre: null,
        descripcion: null
    });

    var isLoading = ref(false);
    var tandas = ref([]);
    var filter = reactive({
        isOpen: false,
        nombre: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var pagination = ref({});

    const getTandas = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                tandas.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tandas',
                body: 'No se pudo consultar el listado de tandas, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentTanda = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                tanda.id = data.id;
                tanda.nombre = data.nombre;
                tanda.descripcion = data.descripcion;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tanda',
                body: 'No se pudo consultar el tanda, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getTandas();
        isLoading.value = false;
    }
    
    return {
        filter,
        tanda,
        tandas,
        pagination,
        isLoading,
        getTandas,
        getCurrentTanda,
        onPaginationHandle,
        formatDate
    }
}