<template>
    <default-layout>
        <div class="row">
            <div class="col-12">
                <div class="p-3">
                    <h2>Modulos</h2>
                    <p>Modulos disponibles</p>
                </div>
            </div>

            <div v-for="(menu, index) in menus" :key="index" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <router-link :to="menu.route" class="card module shadow-lg rounded m-3 p-3">
                    <div class="d-flex align-items-center">
                        <div class="img-module">
                            <img :src="menu.icon" alt="Doctor module" class="w-50 d-block m-auto" />
                        </div>
                        <div class="mt-2">
                            <h5 class="m-0">{{ menu.name }}</h5>
                            <p>{{ menu.description }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </default-layout>
</template>

<script setup>
import { ref } from 'vue'
import { RouterLink } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const menus = ref([{
    name: 'Medicos',
    description: 'Registro medico',
    icon: require('@/assets/img/modules/doctor.png'),
    route: { 
        name: 'CreateMedico' 
    },
},
{
    name: 'Clientes',
    description: 'Registro clientes',
    icon: require('@/assets/img/modules/cliente.png'),
    route: { 
        name: 'CreateCliente' 
    }
},
{
    name: 'Reclamos',
    description: 'Registro de reclamos',
    icon: require('@/assets/img/modules/pacientes.png'),
    route: { 
        name: 'CreateReclamo' 
    },
},
{
    name: 'Lotes',
    description: 'Registro de lotes',
    icon: require('@/assets/img/modules/lotes.png'),
    route: { 
        name: 'CreateLote' 
    },
},
{
    name: 'Facturas',
    description: 'Registro de facturas',
    icon: require('@/assets/img/modules/factura.png'),
    route: { 
        name: 'CreateFactura' 
    },
}]);
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
.card {
    border: 0;
    border-radius: 10px !important;
    text-decoration: none;
    .module{
        border: 0;
        
    }

    img{
        padding: 10px;
    }
}

.card:hover{
    background-color: $primary;
    cursor: pointer;
    color: white;

    img{
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    }
}
</style>