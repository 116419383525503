<template>
    <div class="modal fade" id="alertDialog" tabindex="-1" aria-labelledby="alertDialog" aria-hidden="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="fs-6 fw-bold" id="alertDialog">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="description">
                        <p>{{ description }}</p>
                    </div>
                    <slot name="body" v-else></slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ closeText }}</button>

                    <custom-button
                        type="button" 
                        :text="acceptText"
                        :is-loading="isLoading"
                        class="btn btn-primary"
                        @click="$emit('accept')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'
import CustomButton from '../button/CustomButton'

export default {
    props: {
        id: null,
        title: null,
        description: null,
        isLoading: {
            default: () => false
        },
        acceptText: {
            default: 'Ok'
        },
        closeText: {
            default: 'Cerrar'
        }
    },
    data: () => ({
        modal: {}
    }),
    components: {
        CustomButton
    },
    mounted() {
        this.modal = new Modal(document.getElementById('alertDialog'), { keyboard: false });
    },
    methods: {
        open (){
            this.modal.show();
        },
        close(){
            this.modal.hide();
        }
    }
}
</script>