import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import { GetAll, Create, Delete, GetByGuid, Update } from '@/services/tipoComprobanteServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useTipoComprobante = () => {
    const router = useRouter();

    const tipoComprobanteInitialState = {
        id: null,
        nombre: null,
        serie: null,
        descripcion: null
    };

    var tipoComprobante = reactive({ ...tipoComprobanteInitialState });

    var isLoading = ref(false);
    var isDeleteLoading = ref(false);
    var tipoComprobantes = ref([]);
    var deletionDialog = ref({});
    var filter = reactive({
        isOpen: false,
        nombre: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var pagination = ref({});
    var deletion = reactive({
        id: null,
        readyToDelete: false,
        description: null
    });

    const rules = {
        nombre: { required },
        serie: { required },
    }

    const validate$ = useVuelidate(rules, tipoComprobante);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Create(tipoComprobante);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                Object.assign(tipoComprobante, tipoComprobanteInitialState);
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
            validate$.value.$reset();
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo de comprobante',
                body: 'No se pudo crear el tipo de comprobante, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Update(tipoComprobante);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo comprobante',
                body: 'No se pudo crear el tipo de comprobante, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getTipoComprobantes = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                tipoComprobantes.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipos de comprobante',
                body: 'No se pudo consultar el listado de tipos de comprobante, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentTipoComprobante = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                tipoComprobante.id = data.id;
                tipoComprobante.nombre = data.nombre;
                tipoComprobante.descripcion = data.descripcion;
                tipoComprobante.serie = data.serie;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipo de comprobante',
                body: 'No se pudo consultar el tipo de comprobante, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onDeleteHandle = (tipoComprobante) => {
        deletion.id = tipoComprobante.id;
        deletion.readyToDelete = true;
        deletion.description = `Estas seguro que quieres eliminar ${tipoComprobante.nombre}?`;
        deletionDialog.value.open();
    }

    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({ name: 'ListTipoComprobante' });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo comprobante',
                body: 'No se pudo eliminar el tipo de comprobante'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getTipoComprobantes();
        isLoading.value = false;
    }
    
    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;

    return {
        validate$,
        filter,
        tipoComprobante,
        tipoComprobantes,
        pagination,
        isLoading,
        isDeleteLoading,
        deletionDialog,
        deletion,
        onCreateHandle,
        onUpdateHandle,
        getTipoComprobantes,
        getCurrentTipoComprobante,
        onPaginationHandle,
        onDeleteHandle,
        onDelete,
        openFilterHandle,
        formatDate
    }
}