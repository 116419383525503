import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import { GetAll, Create, Delete, GetByGuid, Update } from '@/services/especialidadServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useEspecialidad = () => {
    const router = useRouter();

    const especialidadInitialState = {
        id: null,
        nombre: null,
        descripcion: null
    };

    var especialidad = reactive({
        ...especialidadInitialState
    });

    var isLoading = ref(false);
    var isDeleteLoading = ref(false);
    var especialidades = ref([]);
    var deletionDialog = ref({});
    var filter = reactive({
        isOpen: false,
        nombre: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        readyToDelete: false,
        description: null
    });


    var pagination = ref({});

    const rules = {
        nombre: { required },
    }

    const validate$ = useVuelidate(rules, especialidad);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Create(especialidad);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                Object.assign(especialidad, especialidadInitialState);
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
            validate$.value.$reset();
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Especialidades',
                body: 'No se pudo crear la especialidad, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Update(especialidad);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Especialidades',
                body: 'No se pudo crear la especialidad, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getEspecialidades = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                especialidades.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de especialidades',
                body: 'No se pudo consultar el listado de especialidades, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentEspecialidad = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                especialidad.id = data.id;
                especialidad.nombre = data.nombre;
                especialidad.descripcion = data.descripcion;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de especialidades',
                body: 'No se pudo consultar el listado de especialidades, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onDeleteHandle = (especialidad) => {
        deletion.id = especialidad.id;
        deletion.readyToDelete = true;
        deletion.description = `Estas seguro que quieres eliminar ${especialidad.nombre}?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({ name: 'ListEspecialidad' });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Especialidades',
                body: 'No se pudo eliminar la especialidad'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getEspecialidades();
        isLoading.value = false;
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        filter,
        especialidad,
        especialidades,
        pagination,
        isLoading,
        deletion,
        deletionDialog,
        onCreateHandle,
        onUpdateHandle,
        getEspecialidades,
        getCurrentEspecialidad,
        onPaginationHandle,
        formatDate,
        onDeleteHandle,
        openFilterHandle,
        onDelete
    }
}