import Alert from '../components/AlertToast.vue'
import { useToast } from "vue-toastification";
import { useRouter } from 'vue-router'
const router = useRouter();

export const toast = (model) => {
    const toastAlert = useToast();
    var configuration = {
        component: Alert,
        props: {
            ...model
        }
    }

    var options = { 
        position: "top-right",
        timeout: 6000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
    };

    switch(model.variant){
        case 'success':
            options.position = "top-center";
            toastAlert.success({...configuration}, 
                                {...options})
            break;
        case 'danger':
            toastAlert.error({...configuration}, 
                                  {...options})
                break;
        case 'warning':
            toastAlert.warning({...configuration}, 
                                  {...options})
                break;
    }
}

export const goBack = () => {
    router.go(-1);
}

export const variant = {
    error: 'danger',
    success: 'success',
    warning: 'warning'
}

export const formatDate = (date) => {
    if(!date) return '--';
    return new Date(date).toLocaleString('en-US');
}

export const print = (Id, style = null) => {
    const prtHtml = document.getElementById(Id).innerHTML;

    let stylesHtml = style ?? '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }

    // Open the print window
    const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

    WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
}

export const  formatPrice = (price) => {
    return new Intl.NumberFormat().format(price);
}

export const formatMoney = (number) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return USDollar.format(number)
}

export const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
};