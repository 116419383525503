<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary shadow-lg">
        <div class="body-container">
            <div class="d-flex flex-wrap items-center">
                <router-link :to="{ name: 'Menu' }" class="navbar-brand" href="#">
                    <img src="@/assets/logo.png" />
                </router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0 nav-right">
                        <li class="nav-item">
                            <router-link :to="{name: 'Menu'}" class="nav-link active" aria-current="page">
                                Home
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'CreateMedico'}" class="nav-link" aria-current="page">
                                Medicos
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'CreateCliente'}" class="nav-link" aria-current="page">
                                Clientes
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'CreateReclamo'}" class="nav-link" aria-current="page">
                                Reclamos
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'CreateLote'}" class="nav-link" aria-current="page">
                                Lotes
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'CreateFactura'}" class="nav-link" aria-current="page">
                                Facturas
                            </router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Tablas
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <router-link :to="{name: 'CreateEspecialidad'}" class="dropdown-item" aria-current="page">
                                        Especialidades
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'CreateCentro'}" class="dropdown-item" aria-current="page">
                                        Centro médicos
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name: 'CreateAsistente'}" class="dropdown-item" aria-current="page">
                                        Asistentes
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'CreateTipoContacto'}" class="dropdown-item" aria-current="page">
                                        Tipos contactos
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name: 'CreateMetodoPago'}" class="dropdown-item" aria-current="page">
                                        Metodos de Pago
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name: 'CreateTipoComprobante'}" class="dropdown-item" aria-current="page">
                                        Tipos comprobantes
                                    </router-link>
                                </li>
                                <li><hr class="dropdown-divider"></li>
                                <li>
                                    <router-link :to="{name: 'CreateTipoServicio'}" class="dropdown-item" aria-current="page">
                                        Tipo Servicios
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name: 'CreateTipoProcedimiento'}" class="dropdown-item" aria-current="page">
                                        Servicios
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="dropdown-toggle btn btn-primary position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ user.email }}
                                <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                                    <span class="visually-hidden">New alerts</span>
                                </span>
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Configuraciones</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><button @click="onLogoutHandle" class="dropdown-item" href="#">Cerrar Sesión</button></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { RouterLink } from 'vue-router';

import { useAccount } from '@/composables/useAccount';

const { user, onLogoutHandle } = useAccount();

</script>

<style lang="scss" scoped>
.navbar-brand{
    img{
        width: 50px;
    }
}
</style>