import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router';
import { GetAll, Create, Delete, GetByGuid, Update, GetByIdProcedimiento } from '@/services/tipoProcedimientoServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useTipoProcedimiento = () => {
    const router = useRouter();
    
    const tipoProcedimientoInitialState = {
        id: null,
        idGrupoProcedimiento: null,
        nombre: null,
        descripcion: null,
        precioBase: null
    };

    var tipoProcedimiento = reactive({ ...tipoProcedimientoInitialState });

    var isLoading = ref(false);
    var isDeleteLoading = ref(false);
    var tipoProcedimientos = ref([]);
    var deletionDialog = ref({});
    var filter = reactive({
        isOpen: false,
        nombre: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        readyToDelete: false,
        description: null
    });

    var pagination = ref({});

    const rules = {
        nombre: { required },
        idGrupoProcedimiento: { required },
    }

    const validate$ = useVuelidate(rules, tipoProcedimiento);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Create(tipoProcedimiento);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                Object.assign(tipoProcedimiento, tipoProcedimientoInitialState);
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
            validate$.value.$reset();
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo de procedimiento',
                body: 'No se pudo crear el tipo de procedimiento, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Update(tipoProcedimiento);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo de procedimiento',
                body: 'No se pudo actualizar el tipo de procedimiento, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getTipoProcedimientos = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                tipoProcedimientos.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipo de procedimientos',
                body: 'No se pudo consultar el listado de tipo de procedimientos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getTipoProcedimientoByIdGrupo = async (idProcedimiento) => {
        try {
            if(!idProcedimiento) return;

            isLoading.value = true;

            const response = await GetByIdProcedimiento(idProcedimiento);
    
            if(response.status == httpStatusCode.OK){
                tipoProcedimientos.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipo de procedimientos',
                body: 'No se pudo consultar el listado de tipo de procedimientos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentTipoProcedimiento = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                tipoProcedimiento.id = data.id;
                tipoProcedimiento.idGrupoProcedimiento = data.idGrupoProcedimiento;
                tipoProcedimiento.nombre = data.nombre;
                tipoProcedimiento.descripcion = data.descripcion;
                tipoProcedimiento.precioBase = data.precioBase;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipo de procedimientos',
                body: 'No se pudo consultar el tipo de procedimientos, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }


    const onDeleteHandle = (tipoProcedimiento) => {
        deletion.id = tipoProcedimiento.id;
        deletion.readyToDelete = true;
        deletion.description = `Estas seguro que quieres eliminar ${tipoProcedimiento.nombre}?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({ name: 'ListTipoProcedimiento' });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo de procedimientos',
                body: 'No se pudo eliminar el tipo de procedimiento'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getTipoProcedimientos();
        isLoading.value = false;
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        filter,
        tipoProcedimiento,
        tipoProcedimientos,
        pagination,
        isLoading,
        isDeleteLoading,
        deletionDialog,
        deletion,
        getTipoProcedimientos,
        getCurrentTipoProcedimiento,
        getTipoProcedimientoByIdGrupo,
        onPaginationHandle,
        onCreateHandle,
        onUpdateHandle,
        onDeleteHandle,
        onDelete,
        openFilterHandle,
        formatDate
    }
}