<template>
  <router-view/>
</template>

<style lang="scss">
body,
html{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
}

.module-img{
    fill: #fff !important;
    stroke: #fff !important;
}

.header-line{
    background: hsla(0,0%,93.3%,.151);
    border: 1.5px dashed hsla(0,0%,81.2%,.404);
    border-radius: 10px;

    .icon-header{
        background: rgba(90,161,243,.404);
        padding: 15px;
        border-radius: 5px;
        color: #fff;
        i{
            font-size: 1.5em;
        }
    }
}
</style>