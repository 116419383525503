
import Base from '@/components/MainWrapper.vue'

import ListEspecialidades from '@/views/tablas/especialidades/ListEspecialidad.vue'
import CreateEspecialidades from '@/views/tablas/especialidades/CreateEspecialidad.vue';
import UpdateEspecialidades from '@/views/tablas/especialidades/UpdateEspecialidad.vue';

import ListCentros from '@/views/tablas/centroMedicos/ListCentroMedico.vue'
import CreateCentros from '@/views/tablas/centroMedicos/CreateCentroMedico.vue';
import UpdateCentros from '@/views/tablas/centroMedicos/UpdateCentroMedico.vue';

import ListAsistente from '@/views/tablas/asistentes/ListAsistente.vue'
import CreateAsistente from '@/views/tablas/asistentes/CreateAsistente.vue';
import UpdateAsistente from '@/views/tablas/asistentes/UpdateAsistente.vue';

import ListTipoContacto from '@/views/tablas/tipoContactos/ListTipoContacto.vue'
import CreateTipoContacto from '@/views/tablas/tipoContactos/CreateTipoContacto.vue';
import UpdateTipoContacto from '@/views/tablas/tipoContactos/UpdateTipoContacto.vue';

import ListMetodoPago from '@/views/tablas/metodoPagos/ListMetodoPago.vue'
import CreateMetodoPago from '@/views/tablas/metodoPagos/CreateMetodoPago.vue';
import UpdateMetodoPago from '@/views/tablas/metodoPagos/UpdateMetodoPago.vue';

import ListTipoComprobante from '@/views/tablas/tipoComprobantes/ListTipoComprobante.vue'
import CreateTipoComprobante from '@/views/tablas/tipoComprobantes/CreateTipoComprobante.vue';
import UpdateTipoComprobante from '@/views/tablas/tipoComprobantes/UpdateTipoComprobante.vue';

import ListTipoServicio from '@/views/tablas/tipoServicios/ListTipoServico.vue'
import CreateTipoServicio from '@/views/tablas/tipoServicios/CreateTipoServico.vue';
import UpdateTipoServicio from '@/views/tablas/tipoServicios/UpdateTipoServico.vue';

import ListTipoProcedimiento from '@/views/tablas/servicios/ListServico.vue'
import CreateTipoProcedimiento from '@/views/tablas/servicios/CreateServico.vue';
import UpdateTipoProcedimiento from '@/views/tablas/servicios/UpdateServico.vue';



const tablas = [{
    path: '/tablas',
    component: Base,
    children: 
    [{
        path: 'especialidades',
        name: 'ListEspecialidad',
        component: ListEspecialidades,
        meta:{
            title: 'Especialidades',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'especialidades/create',
        name: 'CreateEspecialidad',
        component: CreateEspecialidades,
        meta:{
            title: 'Especialidades',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'especialidades/update/:guid',
        name: 'UpdateEspecialidad',
        component: UpdateEspecialidades,
        meta:{
            title: 'Especialidades',
            requiredAuth: true,
            roles: []
        }
    },
    //CENTROS
    {
        path: 'centros',
        name: 'ListCentro',
        component: ListCentros,
        meta:{
            title: 'Centros',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'centros/create',
        name: 'CreateCentro',
        component: CreateCentros,
        meta:{
            title: 'Centros',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'centros/update/:guid',
        name: 'UpdateCentro',
        component: UpdateCentros,
        meta:{
            title: 'Centros',
            requiredAuth: true,
            roles: []
        }
    },
    //ASISTENTES
    {
        path: 'asistentes',
        name: 'ListAsistente',
        component: ListAsistente,
        meta:{
            title: 'Asistentes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'asistentes/create',
        name: 'CreateAsistente',
        component: CreateAsistente,
        meta:{
            title: 'Asistentes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'asistentes/update/:guid',
        name: 'UpdateAsistente',
        component: UpdateAsistente,
        meta:{
            title: 'Asistentes',
            requiredAuth: true,
            roles: []
        }
    },
    //TIPO CONTACTO
    {
        path: 'tipocontactos',
        name: 'ListTipoContacto',
        component: ListTipoContacto,
        meta:{
            title: 'Tipo contactos',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tipocontactos/create',
        name: 'CreateTipoContacto',
        component: CreateTipoContacto,
        meta:{
            title: 'Tipo contactos',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tipocontactos/update/:guid',
        name: 'UpdateTipoContacto',
        component: UpdateTipoContacto,
        meta:{
            title: 'Tipo contactos',
            requiredAuth: true,
            roles: []
        }
    },
    //METODO DE PAGO
    {
        path: 'metodopago',
        name: 'ListMetodoPago',
        component: ListMetodoPago,
        meta:{
            title: 'Metodo pago',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'metodopago/create',
        name: 'CreateMetodoPago',
        component: CreateMetodoPago,
        meta:{
            title: 'Metodo pago',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'metodopago/update/:guid',
        name: 'UpdateMetodoPago',
        component: UpdateMetodoPago,
        meta:{
            title: 'Metodo pago',
            requiredAuth: true,
            roles: []
        }
    },
    //TIPO COMPROBANTE
    {
        path: 'tipocomprobantes',
        name: 'ListTipoComprobante',
        component: ListTipoComprobante,
        meta:{
            title: 'Tipos comprobantes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tipocomprobantes/create',
        name: 'CreateTipoComprobante',
        component: CreateTipoComprobante,
        meta:{
            title: 'Tipos comprobantes',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tipocomprobantes/update/:guid',
        name: 'UpdateTipoComprobante',
        component: UpdateTipoComprobante,
        meta:{
            title: 'Tipos comprobantes',
            requiredAuth: true,
            roles: []
        }
    },
    //TIPO SERVICIO (GRUPO PROCEDIMIENTO)
    {
        path: 'tiposervicios',
        name: 'ListTipoServicios',
        component: ListTipoServicio,
        meta:{
            title: 'Tipos servicios',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tiposervicios/create',
        name: 'CreateTipoServicio',
        component: CreateTipoServicio,
        meta:{
            title: 'Tipos servicios',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tiposervicios/update/:guid',
        name: 'UpdateTipoServicio',
        component: UpdateTipoServicio,
        meta:{
            title: 'Tipos servicios',
            requiredAuth: true,
            roles: []
        }
    },
    //TIPO DE PROCEDIMIENTOS
    {
        path: 'tipoprocedimientos',
        name: 'ListTipoProcedimiento',
        component: ListTipoProcedimiento,
        meta:{
            title: 'Tipos servicios',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tipoprocedimientos/create',
        name: 'CreateTipoProcedimiento',
        component: CreateTipoProcedimiento,
        meta:{
            title: 'Tipos servicios',
            requiredAuth: true,
            roles: []
        }
    },
    {
        path: 'tipoprocedimientos/update/:guid',
        name: 'UpdateTipoProcedimiento',
        component: UpdateTipoProcedimiento,
        meta:{
            title: 'Tipos servicios',
            requiredAuth: true,
            roles: []
        }
    },
]
}];

export default tablas;