<template>
    <nav aria-label="Pagination">
        <ul class="pagination float-end">
            <li class="page-item">
                <button @click="actionPreviousPage" class="page-link" :class="{'disabled': !hasPreviousPage}" tabindex="-1" aria-disabled="true">Anterior</button>
            </li>
            <li  v-for="pg in totalPages" :key="pg"  @click="pageChange(pg)">
                <button class="page-link" :class="{ 'active': currentPage == pg }">{{ pg }}</button>
            </li>
            <li class="page-item">
                <button @click="actionNextPage" class="page-link" :class="{'disabled': !hasNextPage}">Siguiente</button>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps({
    totalPages: {
        type: Number,
        default: 0
    },
    pageSize: {
        type: Number,
        default: 0
    },
    currentPage: {
        type: Number,
        default: 0
    },
    nextPage: {
        type: Number,
        default: 0
    },
    hasNextPage: {
        type: Boolean,
        default: false
    },
    hasPreviousPage: {
        type: Boolean,
        default: false
    },
});

var page = ref(1);

const emit = defineEmits(['pageChange']);

const pageChange = (page) => {
    emit('pageChange', page)
}

const actionNextPage = () => {
    let countPage = (props.currentPage + 1);

    if(countPage <= props.totalPages){ 
        page.value += 1; 
        pageChange(page.value);
    }
};

const actionPreviousPage = () => {
    let countPage = (props.currentPage - 1);
    if(countPage >= 1){ 
        page.value -= 1;
        pageChange(page.value);
    }
}
</script>