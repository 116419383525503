<template>
    <default-layout>
        <section>
            <div>
                <control-section-title 
                    title="Médicos"
                    description="Listado de médicos creados"
                    icon="fas fa-user-md">
                    <template v-slot:control>
                        <custom-button 
                            type="button"
                            text="Filtros"
                            class="btn btn-primary"
                            @click="openFilterHandle()"
                            icon="fas fa-filter fa-sm"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div>
                <paginate-table
                    :pagination="pagination"
                    :headers="['#', 
                            'Identificación', 
                            'Nombre',
                            'Apellido',
                            'Email',
                            'Fecha Creación']"
                    :is-loading="isLoading"
                    :is-empty="medicos.length"
                    empty-message="No hay clientes para mostrar"
                    @pageChange="onPaginationHandle($event)">
                    
                    <template v-slot:tr>
                        <tr v-for="medico in medicos" :key="medico.id" @click="route.push({
                                name: 'UpdateMedico', 
                                params: { guid: medico.guid }
                            })" class="cursor-pointer">
                            <th scope="row">{{ medico.id }}</th>
                            <td>{{ medico.identificacion }}</td>
                            <td>{{ medico.nombre }}</td>
                            <td>{{ medico.apellido }}</td>
                            <td>{{ medico.email }}</td>
                            <td>{{ formatDate(medico.createdDate) }}</td>
                        </tr>
                    </template>
                </paginate-table>
            </div>
        </section>

        <custom-float-button 
            :options="[{
                route: 'ListMedico',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateMedico',
                icon: 'fas fa-plus'
            }]"
        />

        <side-nav 
                title="Filters" 
                :is-open="filter.isOpen" 
                @cancel="openFilterHandle">
            
                <form @submit.stop.prevent="getMedicos" class="row">
                    <div class="col-12">
                        <custom-input
                            id="codigo"
                            placeholder="Código"
                            label="Código"
                            type="text"
                            v-model="filter.codigo"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="identificacion"
                            placeholder="Identificación"
                            label="Identificación"
                            type="text"
                            v-model="filter.identificacion"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            type="text"
                            v-model="filter.nombre"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="email"
                            placeholder="Email"
                            label="Email"
                            type="email"
                            v-model="filter.email"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaDesde"
                            placeholder="Fecha Desde"
                            label="Fecha Desde"
                            type="date"
                            v-model="filter.dateFrom"
                        />
                    </div>

                    <div class="col-12">
                        <custom-input
                            id="fechaHasta"
                            placeholder="Fecha Hasta"
                            label="Fecha Hasta"
                            type="date"
                            v-model="filter.dateTo"
                        />
                    </div>

                    <div class="col-12">
                        <custom-button 
                            type="submit"
                            text="Filtrar"
                            class="btn btn-primary float-end"
                            :is-loading="isLoading"
                        />
                    </div>
                </form>
        </side-nav>
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import SideNav from '@/components/SideNav.vue'
import PaginateTable from '@/components/table/PaginateTable.vue'
import { useMedico } from '@/composables/useMedico'

const route = useRouter();

const { 
    medicos,
    filter,
    isLoading,
    pagination,
    getMedicos,
    formatDate,
    openFilterHandle,
    onPaginationHandle
} = useMedico();


onMounted(async () => {
    await getMedicos();
});
</script>