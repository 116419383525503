<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de reclamos"
                    description="Complete el formulario y cree un nuevo reclamo"
                    icon="fas fa-user-injured">
                    <template v-slot:control>
                        <custom-button 
                            :text="editingReadOnly ? 'Editar': 'Cancelar'"
                            class="btn btn-warning text-white mx-2"
                            @click="() => editingReadOnly = !editingReadOnly"
                            type="button"
                        />

                        <custom-button 
                            text="Eliminar"
                            class="btn btn-danger text-white mx-2"
                            @click="onDeleteHandle(procedimiento)"
                            type="button"
                            :disabled="editingReadOnly"
                        />

                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                            :disabled="editingReadOnly"
                        />
                    </template>        
                </control-section-title>
            </div>

            <section class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                        id="medico"
                        label="Médicos"
                        placeholder="Seleccione un médico"
                        v-model="procedimiento.idMedico"
                        :errors="validate$.idMedico.$errors" 
                        :data="medicos"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                        :disabled="editingReadOnly"/>
            </section>
            
            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                        id="cliente"
                        label="ARS"
                        placeholder="Seleccione un cliente"
                        v-model="procedimiento.paciente.idCliente"
                        :errors="validate$.paciente.idCliente.$errors" 
                        :data="clientes"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                        id="nss"
                        label="NSS"
                        type="text"
                        placeholder="NSS"
                        v-model="procedimiento.paciente.nss"
                        :errors="validate$.paciente.nss.$errors"
                        @change="getPacienteByNss()"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                            id="caso"
                            placeholder="No. Caso"
                            label="No. Caso"
                            type="text"
                            v-model="procedimiento.id"
                            disabled
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                        id="tipoIdentificacion"
                        label="Tipo identificación"
                        v-model="procedimiento.paciente.idTipoIdentificacion"
                        :errors="validate$.paciente.idTipoIdentificacion.$errors" 
                        :data="tipoIdentificaciones"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.nombre"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                        id="identificacion"
                        label="Identificación"
                        type="text"
                        placeholder="Identificación"
                        v-model="procedimiento.paciente.identificacion"
                        :errors="validate$.paciente.identificacion.$errors"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                            id="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            type="text"
                            v-model="procedimiento.paciente.nombre"
                            :errors="validate$.paciente.nombre.$errors"
                            :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-calendar
                        id="fechaNacimiento"
                        placeholder="Fecha Nacimiento"
                        label="Fecha Nacimiento"
                        v-model="procedimiento.paciente.fechaNacimiento"
                        :errors="validate$.paciente.fechaNacimiento.$errors" 
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                        id="sexo"
                        label="Sexo"
                        v-model="procedimiento.paciente.sexo"
                        :errors="validate$.paciente.sexo.$errors" 
                        :data="[{
                                id: 'F',
                                name: 'Femenino'
                            },
                            {
                                id: 'M',
                                name: 'Masculino'
                        }]"
                        :set-code="(model) => model.id"
                        :set-label="(model) => model.name"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                        id="email"
                        placeholder="Email"
                        label="Email"
                        type="email"
                        v-model="procedimiento.paciente.email"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                            id="telefono"
                            placeholder="Teléfono"
                            label="Teléfono"
                            type="tel"
                            v-model="procedimiento.paciente.telefono"
                            :errors="validate$.paciente.telefono.$errors"
                            :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                        id="direccion"
                        placeholder="Dirección"
                        label="Dirección"
                        type="text"
                        v-model="procedimiento.paciente.direccion"
                        :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                            id="diagnostico"
                            placeholder="Diagnóstico"
                            label="Diagnóstico"
                            type="text"
                            v-model="procedimiento.descripcion"
                            :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-md-12 col-xl-12 col-lg-12">
                <section-title 
                    title="Procedimientos"
                    description="Complete el formulario y cree un nuevo cliente"
                    icon="far fa-address-book" 
                />

                <div>
                    <section class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                            <div class="px-4 py-3 header-line">
                                <form @submit.stop.prevent="addDetalleHandle">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-select 
                                                id="grupoProcedimiento"
                                                label="Tipo Servicio"
                                                v-model="procedimientoDetalle.grupoProcedimiento"
                                                :errors="vDetalle$.grupoProcedimiento.$errors" 
                                                :data="grupoProcedimientos"
                                                :set-code="(model) => model"
                                                :set-label="(model) => model.nombre"
                                                @change="() => {
                                                    procedimientoDetalle.precio = null;
                                                    procedimientoDetalle.tipoProcedimiento = null;
                                                    getTipoProcedimientoByIdGrupo(procedimientoDetalle.grupoProcedimiento?.id);
                                                }"
                                                :disabled="editingReadOnly"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-select 
                                                    id="tipoServicios"
                                                    label="Servicios"
                                                    v-model="procedimientoDetalle.tipoProcedimiento"
                                                    :errors="vDetalle$.tipoProcedimiento.$errors" 
                                                    :data="tipoProcedimientos"
                                                    :set-code="(model) => model"
                                                    :set-label="(model) => model.nombre"
                                                    @change="() => {
                                                        procedimientoDetalle.precio = null;
                                                        changePrice(procedimientoDetalle.tipoProcedimiento?.precioBase);
                                                    }"
                                                    :disabled="editingReadOnly"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-input
                                                    id="cantidad"
                                                    placeholder="Cantidad"
                                                    label="Cantidad"
                                                    type="number"
                                                    v-model="procedimientoDetalle.cantidad"
                                                    :errors="vDetalle$.cantidad.$errors"
                                                    :disabled="editingReadOnly"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-input
                                                        id="precio"
                                                        placeholder="Precio"
                                                        label="Precio"
                                                        type="number"
                                                        v-model="procedimientoDetalle.precio"
                                                        :errors="vDetalle$.precio.$errors"
                                                        :disabled="editingReadOnly"
                                            />
                                        </div>
                                                    
                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="cobertura"
                                                        placeholder="%"
                                                        label="%"
                                                        type="number"
                                                        v-model="procedimientoDetalle.ptcCobertura"
                                                        :disabled="editingReadOnly"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                            <custom-input
                                                        id="descuento"
                                                        placeholder="Descuento"
                                                        label="Descuento"
                                                        type="number"
                                                        v-model="procedimientoDetalle.descuento"
                                                        :disabled="editingReadOnly"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-2 col-xl-2 col-lg-2">
                                            <custom-input
                                                        id="autorizacion"
                                                        placeholder="Autorizacion"
                                                        label="Autorizacion"
                                                        type="text"
                                                        v-model="procedimientoDetalle.noAutorizacion"
                                                        :disabled="editingReadOnly"
                                            />
                                        </div>

                                        <div class="d-none">
                                            <button type="submit"></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                            <single-table
                                    :pagination="pagination"
                                    :headers="[
                                            'Grupo', 
                                            'Tipo Proceso', 
                                            'Cantidad', 
                                            'Precio',
                                            'Total',
                                            'Cobertura',
                                            'Descuento',
                                            'Diferencia',
                                            'Autorización',
                                            ''
                                        ]"
                                    :is-loading="false"
                                    :is-empty="procedimiento.detalles.length"
                                    empty-message="No hay datos procedimientos">
                                                
                                    <template v-slot:tr>
                                        <tr v-for="(proc, index) in procedimiento.detalles" :key="index">
                                            <td>{{ proc.grupoProcedimiento?.nombre ?? proc.tipoProcedimiento?.grupoProcedimiento?.nombre }}</td>
                                            <td>{{ proc.tipoProcedimiento?.nombre }}</td>
                                            <td>{{ proc.cantidad }}</td>
                                            <td>{{ formatPrice(proc.precio) }}</td>
                                            <td>{{ formatPrice(proc.total) }}</td>
                                            <td>{{ formatPrice(proc.cobertura) }}</td>
                                            <td>{{ formatPrice(proc.descuento) }}</td>
                                            <td>{{ formatPrice(proc.diferencia) }}</td>
                                            <td>{{ proc.noAutorizacion }}</td>
                                            <td>
                                                <button 
                                                        @click="deleteProcedimientoDetalleHandle(proc, index)" 
                                                        type="button" 
                                                        class="btn btn-danger btn-actions ms-2"
                                                        :disabled="editingReadOnly">
                                                        <i class="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                            </single-table>
                        </div>
                    </section>
                </div>
            </div>
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListReclamo',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateReclamo',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomCalendar from '@/components/input/CustomCalendar.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import SingleTable from '@/components/table/SingleTable.vue'
import { usePaciente } from '@/composables/usePaciente'
import { useReclamo } from '@/composables/useReclamo'
import { useCliente } from '@/composables/useCliente'
import { useMedico } from '@/composables/useMedico'
import { useTipoIdentificacion } from '@/composables/useTipoIdentificacion'
import { useGrupoProcedimiento } from '@/composables/useGrupoProcedimiento'
import { useTipoProcedimiento } from '@/composables/useTipoProcedimiento'

const editingReadOnly = ref(true);

const { 
    validate$,
    vDetalle$,
    procedimiento,
    procedimientoDetalle,
    isLoading,
    addDetalleHandle,
    onCreateHandle,
    getPacienteByNss,
    deleteProcedimientoDetalleHandle,
    getCurrentReclamo
} = useReclamo();

const route = useRoute();

const { 
    formatPrice
} = usePaciente();

const { clientes, getClientes } = useCliente();
const { tipoIdentificaciones, getTipoIdentificaciones } = useTipoIdentificacion();
const { medicos, getMedicos } = useMedico();
const { tipoProcedimientos, getTipoProcedimientoByIdGrupo } = useTipoProcedimiento();
const { grupoProcedimientos, getGrupoProcedimientos } = useGrupoProcedimiento();

onMounted(async () => {
    await getCurrentReclamo(route.params.guid)
    await getTipoIdentificaciones();
    await getClientes();
    await getMedicos();
    await getGrupoProcedimientos();
});

const changePrice = (precioBase) => {
    procedimientoDetalle.precio = precioBase;
}

</script>