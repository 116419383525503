import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router';
import { GetAll, Create, Delete, GetByGuid, Update } from '@/services/tipoContactoServices';
import { toast, variant, formatDate } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';

export const useTipoContacto = () => {
    const router = useRouter();

    const tipoContactoInitialState = {
        id: null,
        nombre: null,
        descripcion: null
    }
    
    var tipoContacto = reactive({ ...tipoContactoInitialState });

    var isLoading = ref(false);
    var isDeleteLoading = ref(false);
    var tipoContactos = ref([]);
    var deletionDialog = ref({});
    var filter = reactive({
        isOpen: false,
        nombre: null,
        descripcion: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        readyToDelete: false,
        description: null
    });

    var pagination = ref({});

    const rules = {
        nombre: { required },
    }

    const validate$ = useVuelidate(rules, tipoContacto);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Create(tipoContacto);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                Object.assign(tipoContacto, tipoContactoInitialState);
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
            validate$.value.$reset();
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo de contactos',
                body: 'No se pudo crear el tipo de contactos, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            const response = await Update(tipoContacto);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Tipo de contactos',
                body: 'No se pudo actualizar el tipo de contacto, consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getTipoContactos = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                tipoContactos.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipos de rnc',
                body: 'No se pudo consultar el listado de tipos de rnc, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentTipoContacto = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                tipoContacto.id = data.id;
                tipoContacto.nombre = data.nombre;
                tipoContacto.descripcion = data.descripcion;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de tipo de rnc',
                body: 'No se pudo consultar el tipo de rnc, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onDeleteHandle = (tipoContacto) => {
        deletion.id = tipoContacto.id;
        deletion.readyToDelete = true;
        deletion.description = `Estas seguro que quieres eliminar ${tipoContacto.nombre}?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });

                router.push({ name: 'ListTipoContacto' });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Especialidades',
                body: 'No se pudo eliminar la especialidad'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getTipoContactos();
        isLoading.value = false;
    }

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        filter,
        tipoContacto,
        tipoContactos,
        pagination,
        deletion,
        deletionDialog,
        isLoading,
        isDeleteLoading,
        getTipoContactos,
        getCurrentTipoContacto,
        onPaginationHandle,
        openFilterHandle,
        onCreateHandle,
        onUpdateHandle,
        onDeleteHandle,
        onDelete,
        formatDate
    }
}