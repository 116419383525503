import { CORE } from "./commonServices";

/**
 * Recovery account
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Recovery = async (email) =>{
    try{
        const result = await CORE.put(`Recovery/${email}`);
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Get Valid Key
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {String}
 * @return {Object|Array}
 **/
export const Validkey = async (guid) =>{
    try{
        const result = await CORE.get(`Recovery/${guid}`);
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Change password
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Change = async (guid, payLoad) => {
    try {
        const result = await CORE.post(`Recovery/Change/${guid}`, payLoad);
        return result; 
    } catch (error) {
        return error.response;
    }
}