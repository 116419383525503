<template>
    <default-layout>
        <form @submit.stop.prevent="onUpdateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Actualización de metodo de pago"
                    description="Complete el formulario y cree un nuevo metodo de pago"
                    icon="fas fa-building">
                    <template v-slot:control>
                        <custom-button 
                            :text="editingReadOnly ? 'Editar': 'Cancelar'"
                            class="btn btn-warning text-white mx-2"
                            @click="() => editingReadOnly = !editingReadOnly"
                            type="button"
                        />

                        <custom-button 
                            text="Eliminar"
                            class="btn btn-danger text-white mx-2"
                            @click="onDeleteHandle(metodoPago)"
                            type="button"
                            :disabled="editingReadOnly"
                        />

                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                            :disabled="editingReadOnly"
                        />
                    </template>        
                </control-section-title>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="nombre"
                    placeholder="Nombre"
                    label="Nombre"
                    type="text"
                    v-model="metodoPago.nombre"
                    :errors="validate$.nombre.$errors"
                    :disabled="editingReadOnly"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                <custom-input
                    id="descripcion"
                    placeholder="Descripción"
                    label="Descripción"
                    type="text"
                    v-model="metodoPago.descripcion"
                    :disabled="editingReadOnly"
                />
            </div>
        </form>
        
        <alert-dialog 
                ref="deletionDialog"
                title="Eliminación de metodo de pago"
                :description="deletion.description"
                accept-text="Si"
                close-text="No"
                :is-loading="isDeleteLoading"
                @accept="onDelete()" 
        />

        <custom-float-button 
            :options="[{
                route: 'ListMetodoPago',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateMetodoPago',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import AlertDialog from '@/components/modal/AlertDialog.vue'
import { useMetodoPago } from '@/composables/useMetodoPago'


const editingReadOnly = ref(true);

const { 
    validate$,
    metodoPago,
    isLoading,
    deletion,
    deletionDialog,
    isDeleteLoading,
    onUpdateHandle,
    getCurrentMetodoPago,
    onDeleteHandle,
    onDelete,
} = useMetodoPago();

const route = useRoute();

onMounted(async () => {
    await getCurrentMetodoPago(route.params.guid)
})

</script>