import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { Create, GetAll, Delete, Update, GetByGuid, GetByIdentificacion } from '@/services/pacienteServices';
import { Create as CreateDiagnostico, Delete as DeleteDiagnostico } from '@/services/diagnosticoServices';
import { Create as CreateProcedimiento, Delete as DeleteProcedimiento } from '@/services/procedimientoServices'
import { toast, variant, formatDate, formatPrice } from '@/helpers/utility';
import { httpStatusCode } from '@/helpers/httpStatusCode';
import { estatus } from '@/helpers/enums/estatus'

export const usePaciente = () => {
    var paciente = reactive({
        id: null,
        nombre: null,
        idTipoIdentificacion: null,
        identificacion: null,
        idCliente: null,
        sexo: null,
        email: null,
        direccion: null,
        fechaNacimiento: null,
        telefono: null,
        nss: null,
        diagnosticos: [],
        procedimientos: []
    });

    const diagnosticoInitialState = {
        id: null,
        idPaciente: null,
        descripcion: null
    };

    var diagnostico = reactive({ ...diagnosticoInitialState });

    const procedimientoInitialState = {
        idMedico: null,
        medico: null,
        fecha: null,
        descripcion: null,
        noCaso: null,
        noAutorizacion: null,
        detalles: []
    }

    var procedimiento = reactive({ ...procedimientoInitialState });

    const procedimientoDetalleInitialState = {
        idProcedimiento: null,
        idTipoProcedimiento: null,
        idGrupoProcedimiento: null,
        ptcCobertura: null,
        tipoProcedimiento: {
            id: null,
            nombre: null
        },
        cantidad: null,
        precio: null,
        total: null,
        cobertura: null,
        descuento: null
    }

    var procedimientoDetalle = reactive({ ...procedimientoDetalleInitialState });

    var isLoading = ref(false);
    var isDiagnosticoLoading = ref(false);
    var isDeleteLoading = ref(false);
    var deletionDialog = ref({});
    var pacientes = ref([]);
    var filter = reactive({
        isOpen: false,
        codigo: null,
        idTipoRnc: null,
        rnc: null,
        nombre: null,
        razon: null,
        email: null,
        dateFrom: null,
        dateTo: null,
        pageSize: null,
        pageNumber: null,
    });

    var deletion = reactive({
        id: null,
        description: null
    });

    var pagination = ref({});

    const rules = {
        idTipoIdentificacion: { required },
        identificacion: { required },
        idCliente: { required },
        nombre: { required },
        fechaNacimiento: { required },
        sexo: { required },
        nss: { required },
        telefono: { required }
    }

    const diagnosticoRules = {
        descripcion: { required }
    }

    const procedimientoRules = {
        medico: { 
            id: {
                required
            }
        }
    }

    const procedimientoDetalleRules = {
        idGrupoProcedimiento: { required },
        tipoProcedimiento: { 
            id: {
                required
            }
        },
        cantidad: {
            required
        },
        precio: {
            required
        }
    }

    const validate$ = useVuelidate(rules, paciente);
    const vDiagnostico$ = useVuelidate(diagnosticoRules, diagnostico);
    const vProcedimiento$ = useVuelidate(procedimientoRules, procedimiento);
    const vProcedimientoDetalle$ = useVuelidate(procedimientoDetalleRules, procedimientoDetalle);

    const onCreateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }

            isLoading.value = true;
            
            var pacientePayLoad = {
                ...paciente,
                procedimientos: paciente.procedimientos.map((proc) => {
                    return{
                        idMedico: proc.medico.id,
                        descripcion: proc.descripcion,
                        idEstatus: estatus.Activo.id,
                        noCaso: proc.noCaso,
                        noAutorizacion: proc.noAutorizacion,
                        detalles: proc.detalles.map((dt) => {
                            return {
                                idTipoProcedimiento: dt.tipoProcedimiento.id,
                                cantidad: dt.cantidad,
                                precio: dt.precio,
                                total: dt.total,
                                cobertura: dt.cobertura
                            }
                        })
                    }
                }),
            }
            
            const response = await Create(pacientePayLoad);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Creación de paciente',
                body: 'No se pudo crear el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getPacientes = async () => {
        try {

            isLoading.value = true;

            const response = await GetAll(filter);
    
            if(response.status == httpStatusCode.OK){
                pacientes.value = response.data.value;
                pagination.value = response.data.meta;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }

            isLoading.value = false;
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de pacientes',
                body: 'No se pudo consultar el listado de pacientes, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getCurrentPaciente = async (guid) => {
        try {
            const response = await GetByGuid(guid);
    
            if(response.status == httpStatusCode.OK){
                const { data: { value: data } } = response;
                paciente.id = data.id;
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de paciente',
                body: 'No se pudo consultar el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const getPacienteByIdentificacion = async (identificacion) => {
        try {
            validate$.value.$reset();

            if(identificacion){
                
                const response = await GetByIdentificacion(identificacion);
    
                if(response.status == httpStatusCode.OK){
                    const { data: { value: data } } = response;
                
                    if(data){
                        paciente.id = data.id;
                        paciente.nombre = data.nombre;
                        paciente.idTipoIdentificacion = data.idTipoIdentificacion;
                        paciente.identificacion = data.identificacion;
                        paciente.idCliente = data.idCliente;
                        paciente.sexo = data.sexo;
                        paciente.email = data.email;
                        paciente.direccion = data.direccion;
                        paciente.fechaNacimiento = data.fechaNacimiento;
                        paciente.telefono = data.telefono;
                        paciente.nss = data.nss;
                        paciente.diagnosticos = data.diagnosticos;
                        paciente.procedimientos = data.procedimientos;
                    }
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                }
            }
            
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Consulta de paciente',
                body: 'No se pudo consultar el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onUpdateHandle = async () => {
        try {
            const isValid = await validate$.value.$validate();
            if(!isValid){
                return;
            }
            
            isLoading.value = true;

            const response = await Update(paciente);
    
            if(response.status == httpStatusCode.OK){
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de paciente',
                body: 'No se pudo actualizar el paciente, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const onPaginationHandle = async (page) => {
        isLoading.value = true;
        filter.pageNumber = page;
        await getPacientes();
        isLoading.value = false;
    }

    const onDeleteHandle = (paciente, index) => {
        deletion.index = index;
        deletion.id = paciente.id;
        deletion.description = `Estás seguro de quieres eliminar (#${paciente.id} ${paciente.nombre})?`;
        deletionDialog.value.open();
    }
    
    const onDelete = async () => {
        try {
            isDeleteLoading.value = true;
    
            const response = await Delete(deletion.id);
    
            if(response.status == httpStatusCode.OK){
                deletionDialog.value.close();
                pacientes.value.splice(deletion.index, 1);
                toast({
                    variant: variant.success,
                    title: response.data.title,
                    body:  response.data.message
                });
            }else{
                toast({
                    collection: true,
                    variant: variant.error,
                    title: response.data.title,
                    body:  response.data.messages
                });
            }
    
            isDeleteLoading.value = false;
        } catch (error) {
            isDeleteLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Pacientes',
                body: 'No se pudo consultar el listado de pacientes, por favor consulte el centro de soporte'
            });
    
            throw new Error(error);   
        }
    }

    const addDiagnosticoHandle = async () => {
        try {
            const isValid = await vDiagnostico$.value.$validate();
            if(!isValid){
                return;
            }

            if(paciente.id){
                const response = await CreateDiagnostico({
                    idPaciente: paciente.id,
                    descripcion: diagnostico.descripcion
                });
    
                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });

                    return;
                }
            }
    
            paciente.diagnosticos.push({
                ...diagnostico
            })
    
            Object.assign(diagnostico, diagnosticoInitialState);
            vDiagnostico$.value.$reset();

        } catch (error) {
            isDiagnosticoLoading.value = false;
            toast({
                variant: variant.error,
                title: 'Actualización de paciente',
                body: 'No se pudo actualizar el paciente, por favor consulte con el centro de soporte'
            });
        }
    }

    const addProcedimientoHandle = async () => {
        try {
            const isValid = await vProcedimiento$.value.$validate();
            if(!isValid){
                return;
            }

            if(paciente.id){
                const response = await CreateProcedimiento({
                    idPaciente: paciente.id,
                    idMedico: procedimiento.medico.id,
                    descripcion: procedimiento.descripcion,
                    noCaso: procedimiento.noCaso,
                    noAutorizacion: procedimiento.noAutorizacion,
                    total: procedimiento.total,
                    detalles: procedimiento.detalles.map(detalle => {
                        return {
                            idTipoProcedimiento: detalle.tipoProcedimiento.id,
                            cantidad: detalle.cantidad,
                            precio: detalle.precio,
                            total: detalle.total,
                            cobertura: detalle.cobertura
                        }
                    })
                });


                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });

                    return;
                }
            }
            
            vProcedimiento$.value.$reset();
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Actualización de procedimiento',
                body: 'No se pudo actualizar el procedimiento, por favor consulte con el centro de soporte'
            });

            throw new Error(error);
        }
    }

    const addProcedimientoDetalleHandle = async () => {
        const isValid = await vProcedimientoDetalle$.value.$validate();
        if(!isValid){
            return;
        }

        let total = ((procedimientoDetalle.cantidad * procedimientoDetalle.precio) - procedimientoDetalle.descuento);
        let cobertura = (total * ((procedimientoDetalle.ptcCobertura ?? 0)/100));
        let diferencia = (total - cobertura);

        procedimiento.detalles.push({
            ...procedimientoDetalle,
            total: total,
            cobertura: cobertura,
            descuento: procedimientoDetalle.descuento,
            diferencia: diferencia
        });

        procedimientoDetalle.idProcedimiento = null;
        procedimientoDetalle.idTipoProcedimiento = null;
        procedimientoDetalle.tipoProcedimiento = {
            id: null,
            nombre: null
        }
        procedimientoDetalle.cantidad = null;
        procedimientoDetalle.precio = null;
        procedimientoDetalle.total = null;
        procedimientoDetalle.cobertura = null;
        procedimientoDetalle.ptcCobertura = null;
        procedimientoDetalle.descuento = null;

        vProcedimientoDetalle$.value.$reset();
    };

    const deleteDiagnosticoHandle = async (diag, index) => {
        try {
            if(paciente.id){
                const response = await DeleteDiagnostico(diag.id);
        
                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                     
                    return;
                }
            } 
            
            paciente.diagnosticos.splice(index, 1);
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Diagnóstico',
                body: 'No se pudo eliminar el diagnóstico, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error);  
        }
    };

    const deleteProcedimientoHandle = async (proce, index) => {
        try {
            if(paciente.id){
                const response = await DeleteProcedimiento(proce.id);
        
                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                     
                    return;
                }
            } 

            paciente.procedimientos.splice(index, 1);
        } catch (error) {
            toast({
                variant: variant.error,
                title: 'Procedimiento',
                body: 'No se pudo eliminar el procedimiento, por favor consulte con el centro de soporte'
            });
    
            throw new Error(error); 
        }
    }

    const deleteProcedimientoDetalleHandle = (proce, index) => procedimiento.detalles.splice(index, 1);

    const openFilterHandle = async () => filter.isOpen = !filter.isOpen;
    
    return {
        validate$,
        vDiagnostico$,
        vProcedimiento$,
        vProcedimientoDetalle$,
        paciente,
        procedimientoDetalle,
        isLoading,
        isDeleteLoading,
        pacientes,
        filter,
        procedimiento,
        deletion,
        deletionDialog,
        pagination,
        diagnostico,
        onCreateHandle,
        getPacientes,
        getCurrentPaciente,
        getPacienteByIdentificacion,
        openFilterHandle,
        onUpdateHandle,
        onPaginationHandle,
        onDeleteHandle,
        onDelete,
        addDiagnosticoHandle,
        addProcedimientoHandle,
        addProcedimientoDetalleHandle,
        deleteDiagnosticoHandle,
        deleteProcedimientoHandle,
        deleteProcedimientoDetalleHandle,
        formatPrice,
        formatDate
    }
}