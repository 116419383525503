<template>
    <default-layout>
        <form @submit.stop.prevent="onCreateHandle" class="row">
            <div class="col-12">
                <control-section-title 
                    title="Creación de médico"
                    description="Complete el formulario y cree un nuevo médico"
                    icon="fas fa-user-md">
                    <template v-slot:control>
                        <custom-button 
                            :is-loading="isLoading"
                            text="Guardar"
                            class="btn btn-primary"
                        />
                    </template>        
                </control-section-title>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-select 
                    id="tipoIdentificacion"
                    label="Tipo identificación"
                    v-model="medico.idTipoIdentificacion"
                    :errors="validate$.idTipoIdentificacion.$errors" 
                    :data="tipoIdentificaciones"
                    :set-code="(model) => model.id"
                    :set-label="(model) => model.nombre"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="identificacion"
                    placeholder="Identificación"
                    label="Identificación"
                    type="text"
                    v-model="medico.identificacion"
                    :errors="validate$.identificacion.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="nombre"
                    placeholder="Nombre"
                    label="Nombre"
                    type="text"
                    v-model="medico.nombre"
                    :errors="validate$.nombre.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="apellido"
                    placeholder="Apellido"
                    label="Apellido"
                    type="text"
                    v-model="medico.apellido"
                    :errors="validate$.apellido.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="exequatur"
                    placeholder="Exequatur"
                    label="Exequatur"
                    type="text"
                    v-model="medico.exequatur"
                    :errors="validate$.exequatur.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="noColegio"
                    placeholder="No. Colegio"
                    label="No. Colegio"
                    type="text"
                    v-model="medico.noColegio"
                    :errors="validate$.noColegio.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="email"
                    placeholder="Email"
                    label="Email"
                    type="email"
                    v-model="medico.email"
                    :errors="validate$.email.$errors" 
                />
            </div>

            <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <custom-input
                    id="telefono"
                    placeholder="Teléfono"
                    label="Teléfono"
                    type="tel"
                    v-model="medico.telefono"
                    :errors="validate$.telefono.$errors" 
                />
            </div>
           
            <div class="col-12 col-sm-12 col-md-12 col-xl-21 col-lg-12">
                <custom-input
                    id="direccion"
                    placeholder="Dirección"
                    label="Dirección"
                    type="text"
                    v-model="medico.direccion"
                    :errors="validate$.direccion.$errors" 
                />
            </div>

            <div class="col-12 col-md-6 col-xl-6 col-lg-6">
                <section-title 
                        title="Especialidades"
                        description="Complete el formulario y agregue una especialidad"
                        icon="fas fa-brain" 
                />

                <form @submit.stop.prevent="addEspecialidadHandle" class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
                        <div class="d-flex ">
                            <custom-select 
                                    id="especialidades"
                                    label="Especialidades"
                                    v-model="especialidad.idEspecialidad"
                                    :errors="vEspecialidad$.idEspecialidad.$errors" 
                                    :data="especialidades"
                                    :set-code="(model) => model.id"
                                    :set-label="(model) => model.nombre"
                                    @change="addEspecialidadHandle"
                            />
                        </div>
                        
                        <div v-for="(espec, index) in medico.especialidades" :key="index" class="inline">
                            <div class="d-flex">
                                <custom-select 
                                    id="especialidades"
                                    v-model="espec.idEspecialidad"
                                    :errors="vEspecialidad$.idEspecialidad.$errors" 
                                    :data="especialidades"
                                    :set-code="(model) => model.id"
                                    :set-label="(model) => model.nombre"
                                    disabled
                                    class="ml-3"
                                />
                                <button @click="deleteEspecialidadHandle(espec, index)" class="btn btn-danger btn-actions ms-2" type="button">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
           

            <div class="col-12 col-md-6 col-xl-6 col-lg-6">
                <section-title 
                        title="Centro médico"
                        description="Complete el formulario y agregue los centros médicos"
                        icon="far fa-hospital" 
                />

                <form @submit.stop.prevent="addCentroHandle" class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                        <custom-select 
                            id="centroMedico"
                            label="Centro médico"
                            v-model="centro.idCentro"
                            :errors="vCentro$.idCentro.$errors" 
                            :data="centros"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                        <custom-select 
                            id="tandas"
                            label="Tandas"
                            v-model="centro.idTanda"
                            :errors="vCentro$.idTanda.$errors" 
                            :data="tandas"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                        <custom-select 
                            id="asistentes"
                            label="Asistentes"
                            v-model="centro.idAsistente"
                            :errors="vCentro$.idAsistente.$errors" 
                            :data="asistentes"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                        <button type="submit" class="btn btn-primary mt-4">
                            <i class="fas fa-check"></i>
                        </button>
                    </div> 

                    <div v-for="(cent, index) in medico.centros" :key="index" class="inline">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                                    <custom-select 
                                        id="centroMedico"
                                        v-model="cent.idCentro"
                                        :errors="vCentro$.idCentro.$errors" 
                                        :data="centros"
                                        :set-code="(model) => model.id"
                                        :set-label="(model) => model.nombre"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                                    <custom-select 
                                        id="tandas"
                                        v-model="cent.idTanda"
                                        :errors="vCentro$.idTanda.$errors" 
                                        :data="tandas"
                                        :set-code="(model) => model.id"
                                        :set-label="(model) => model.nombre"
                                        disabled
                                    />
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                    <custom-select 
                                        id="asistentes"
                                        v-model="cent.idAsistente"
                                        :errors="vCentro$.idAsistente.$errors" 
                                        :data="asistentes"
                                        :set-code="(model) => model.id"
                                        :set-label="(model) => model.nombre"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1">
                                    <button @click="deleteCentroHandle(cent, index)" class="btn btn-danger btn-actions" type="button">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                    </div>
                </form>
            </div>

            <div class="col-12">
                <control-section-title 
                    title="Datos DGII"
                    description="Complete el formulario y cree un nuevo médico"
                    icon="fas fa-user-md" />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-input
                        id="usuarioDgii"
                        placeholder="Usuario Dgii"
                        label="Usuario Dgii"
                        type="text"
                        v-model="medico.usuarioDgii"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                <custom-input
                        id="passwordDgii"
                        placeholder="Password Dgii"
                        label="Password Dgii"
                        type="password"
                        v-model="medico.passwordDgii"
                    />
            </div>

            <div class="col-12 col-sm-12">
                <form @submit.stop.prevent="addComprobanteHandle" class="row">
                        <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                            <custom-select 
                                id="tipoComprobante"
                                label="Tipo Comprobantes"
                                v-model="comprobante.idTipoComprobante"
                                :errors="vComprobante$.idTipoComprobante.$errors" 
                                :data="tipoComprobantes"
                                :set-code="(model) => model.id"
                                :set-label="(model) => model.nombre"
                            />
                        </div>

                        <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                            <custom-input
                                id="fechaVence"
                                placeholder="Fecha vencimiento"
                                label="Fecha vencimiento"
                                type="date"
                                v-model="comprobante.fechaVence"
                                :errors="vComprobante$.fechaVence.$errors" 
                            />
                        </div>

                        <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                            <custom-input
                                id="secuenciaInicio"
                                placeholder="Secuencia Inicio"
                                label="Secuencia Inicio"
                                type="number"
                                v-model="comprobante.inicio"
                                :errors="vComprobante$.inicio.$errors" 
                            />
                        </div>

                        <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                            <custom-input
                                id="secuenciaFin"
                                placeholder="Secuencia Fin"
                                label="Secuencia Fin"
                                type="number"
                                v-model="comprobante.fin"
                                :errors="vComprobante$.fin.$errors" 
                            />
                        </div>


                        <div v-for="(comprob, index) in medico.comprobantes" :key="index" class="inline">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                    <custom-select 
                                        id="tipoComprobante"
                                        v-model="comprob.idTipoComprobante"
                                        :errors="vComprobante$.idTipoComprobante.$errors" 
                                        :data="tipoComprobantes"
                                        :set-code="(model) => model.id"
                                        :set-label="(model) => model.nombre"
                                        disabled
                                    />
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                    <custom-input
                                        id="fechaVence"
                                        placeholder="Fecha vencimiento"
                                        type="date"
                                        v-model="comprob.fechaVence"
                                        :errors="vComprobante$.fechaVence.$errors" 
                                        disabled
                                    />
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                                    <custom-input
                                        id="secuenciaInicio"
                                        placeholder="Secuencia Inicio"
                                        type="number"
                                        v-model="comprob.inicio"
                                        :errors="vComprobante$.inicio.$errors"
                                        disabled
                                    />
                                </div>

                                <div class="col-12 col-sm-12 col-md-2 col-xl-3 col-lg-3">
                                    <div class="d-flex">
                                        <custom-input
                                            id="secuenciaFin"
                                            placeholder="Secuencia Fin"
                                            type="number"
                                            v-model="comprob.fin"
                                            :errors="vComprobante$.fin.$errors"
                                            disabled
                                            class="w-100 block"
                                        />
                                        <button @click="deleteComprobanteHandle(comprob, index)" class="btn btn-danger btn-actions ms-2" type="button">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1 d-none">
                            <button type="submit" class="btn btn-primary mt-3 d-none">
                                <i class="fas fa-check"></i>
                            </button>
                        </div>
                </form>
            </div>

            <div class="col-12 col-md-12 col-xl-12 col-lg-12">
                <section-title 
                        title="Datos del cliente"
                        description="Complete el formulario y agregue los centros médicos"
                        icon="far fa-hospital" 
                />

                <form @submit.stop.prevent="addClienteHandle" class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-select 
                            id="cliente"
                            label="Clientes"
                            v-model="cliente.idCliente"
                            :errors="vCliente$.idCliente.$errors" 
                            :data="clientes"
                            :set-code="(model) => model.id"
                            :set-label="(model) => model.nombre"
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                        <custom-input
                                id="codigo"
                                placeholder="Codigo"
                                label="Codigo"
                                type="text"
                                v-model="cliente.codigo"
                                :errors="vCliente$.codigo.$errors" 
                        />
                    </div>

                    <div class="col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1 d-none">
                        <button type="submit" class="btn btn-primary mt-3 d-none">
                            <i class="fas fa-check"></i>
                        </button>
                    </div>

                    <div v-for="(med, index) in medico.clientes" :key="index" class="inline">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                    <custom-select 
                                        id="cliente"
                                        v-model="med.idCliente"
                                        :errors="vCliente$.idCliente.$errors" 
                                        :data="clientes"
                                        :set-code="(model) => model.id"
                                        :set-label="(model) => model.nombre"
                                        disabled
                                    />
                                </div>
                               

                                <div class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
                                    <div class="d-flex">
                                        <custom-input
                                                id="codigo"
                                                placeholder="Codigo"
                                                type="text"
                                                v-model="med.codigo"
                                                :errors="vCliente$.codigo.$errors"
                                                disabled
                                        />
                                        <button @click="deleteClienteHandle(med, index)" class="btn btn-danger btn-actions ms-2" type="button">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </form>
            </div>
        </form>
        
        <custom-float-button 
            :options="[{
                route: 'ListMedico',
                icon: 'fas fa-list-ul'
            },
            {
                route: 'CreateMedico',
                icon: 'fas fa-plus'
            }]"
        />
    </default-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CustomButton from '@/components/button/CustomButton.vue'
import SectionTitle from '@/components/header/SectionTitle.vue'
import ControlSectionTitle from '@/components/header/ControlSectionTitle.vue'
import CustomInput from '@/components/input/CustomInput.vue'
import CustomSelect from '@/components/input/CustomSelect.vue'
import CustomFloatButton from '@/components/button/CustomFloatButton.vue'
import { useMedico } from '@/composables/useMedico'
import { useEspecialidad } from '@/composables/useEspecialidad'
import { useTanda } from '@/composables/useTanda'
import { useCentro } from '@/composables/useCentro'
import { useAsistente } from '@/composables/useAsistente'
import { useTipoIdentificacion } from '@/composables/useTipoIdentificacion'
import { useTipoComprobante } from '@/composables/useTipoComprobante'
import { useCliente } from '@/composables/useCliente'


const { 
    validate$,
    vEspecialidad$,
    vCentro$,
    vComprobante$,
    vCliente$,
    medico,
    especialidad,
    centro,
    comprobante,
    cliente,
    isLoading,
    onCreateHandle,
    addEspecialidadHandle,
    addCentroHandle,
    addComprobanteHandle,
    deleteEspecialidadHandle,
    deleteComprobanteHandle,
    deleteCentroHandle,
    addClienteHandle,
    deleteClienteHandle,
} = useMedico();

const { tipoIdentificaciones, getTipoIdentificaciones } = useTipoIdentificacion();
const { especialidades, getEspecialidades } = useEspecialidad();
const { centros, getCentros } = useCentro();
const { tandas, getTandas } = useTanda();
const { asistentes, getAsistentes } = useAsistente();
const { tipoComprobantes, getTipoComprobantes } = useTipoComprobante();
const { clientes, getClientes } = useCliente();


onMounted(async () => {
    await getTipoIdentificaciones();
    await getEspecialidades();
    await getCentros();
    await getTandas();
    await getAsistentes();
    await getTipoComprobantes();
    await getClientes();
});

</script>