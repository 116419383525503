<template>
    <section class="container">
        <div class="row m-0 min-vh-100 justify-content-center align-items-center">
            <div class="col-12">
                <img 
                    src="@/assets/img/iunauthorized.jpg" 
                    alt="Unauthorized icon"
                    class="img-fluid"/>
                <div>
                    <h1>Usuario no autorizado</h1>
                    <p>No tiene suficientes privilegios para entrar a esta vista</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="js">
export default {

}
</script>

<style lang="scss" scoped>
.container{
    h1, p{
        text-align: center;
    }

    h1{
        font-size: 1.2em;
        font-weight: bold;
    }

    img{
        display: block;
        max-width: 350px;
        margin: 0 auto;
    }
}

</style>