import { CORE } from "./commonServices";


/**
 * Login
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Login = async (model) =>{
    try{
        const result = await CORE.post('Account/Login', model);
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Create User
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Create = async (payLoad) => {
    try {
        const result = await CORE.post(`Account/Register`, payLoad);
        return result; 
    } catch (error) {
        return error.response;
    }
}

/**
 * Get Current User
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const GetCurrentUser = async () =>{
    try{
        const result =  await CORE.get('Account/Currentuser');
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Activate account service
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Activate = async (key) =>{
    try{
        const result =  await CORE.get(`Account/Active/${key}`);
        return result;
    }catch(error){
        return error.response;
    }
};

/**
 * Update account
 * @version 1.0.0
 * @author Luis Rafael Geraldo <lgeraldo@gcunit.com>    
 * @param  {Object}
 * @return {Object|Array}
 **/
export const Change = async (payLoad) => {
    try {
        const result = await CORE.put(`Account/Change`, payLoad);
        return result; 
    } catch (error) {
        return error.response;
    }
}