import { createRouter, createWebHistory } from 'vue-router'
import { useAccountStore } from '@/store/accountStore';

import MenuView from '../views/MenuView.vue'
import LoginView from '../views/auth/LoginView.vue'
import UnauthorizedView from '@/views/auth/UnauthorizedView.vue';
import clientes from './routes/cliente'
import medicos from './routes/medico'
import facturas from './routes/factura'
import lotes from './routes/lote'
import reclamos from './routes/reclamo'
import tablas from './routes/tabla'

const routes = [
  {
    path: '/',
    name: 'Menu',
    component: MenuView,
    meta:{
      title: 'Menu',
      requiredAuth: true,
      roles: []
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: LoginView,
    meta:{
      title: 'Login',
      requiredAuth: false,
      roles: []
    },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: UnauthorizedView,
    meta:{
      title: 'Unauthorized',
      requiredAuth: false,
      roles: []
    },
  },
  ...clientes,
  ...medicos,
  ...facturas,
  ...lotes,
  ...reclamos,
  ...tablas
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const accountStore = useAccountStore();
  const isUserLoggedIn = accountStore.isLoggin;
  const currentUser = isUserLoggedIn ? await accountStore.getCurrentUser() : null;

  if (to.name === 'Login') {
    if (currentUser) {
      next({ name: 'Menu' });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (isUserLoggedIn && currentUser) {
      const hasRequiredRoles = !to.meta.roles || 
                                to.meta.roles.some((rol) => currentUser.roles.includes(rol)) || 
                                !(to.meta.roles.length > 0);
      
      if (hasRequiredRoles) {
        next();
      } else {
        next({ name: 'Unauthorized' });
      }
    } else {
      next({ name: 'Login' });
    }
  }

  next();
});

export default router
