import { defineStore } from 'pinia'
import { toast, variant } from '@/helpers/utility'
import { httpStatusCode } from '@/helpers/httpStatusCode';
import { GetCurrentUser } from '@/services/accountServices';
import { Recovery, Validkey, Change } from '@/services/recoveryServices';
const ACCOUNT_KEY = process.env.VUE_APP_TOKEN_ACCOUNT_KEY;
const REFRESH_KEY = process.env.VUE_APP_REFRESH_TOKEN_KEY;

export const useAccountStore = defineStore('user', {
    state: () => ({
        login: {
            email: null,
            password: null
        },
        user: {
            token: null,
            refreshToken: null,
            identification: null,
            userName: null,
            email: null,
            password: null,
            photo: null,
            phone: null,
            staff: false,
            idUserStatus: 0,
            nextChangePassword: null,
            expires: null,
            roles: []
        },
    }),
    getters: {
        getAccessToken(state) {
            return state.user.token ?? localStorage.getItem(ACCOUNT_KEY);
        },
        getRefreshToken(state) {
            return state.user.refreshToken ?? localStorage.getItem(REFRESH_KEY);
        },
        isLoggin() {
            return this.getAccessToken ?? false;
        }
    },
    actions: {
        setToken(user){
            this.user = user.data.value;
            this.updateTokes(user.data.token, user.data.refreshToken);
        },
        async getCurrentUser() {
            try {
                const response = await GetCurrentUser();

                if(response.status == httpStatusCode.OK){
                    this.user = response.data.value;
                    return response.data.value;
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                }
            } catch (error) {
                toast({
                    variant: variant.error,
                    title: 'Current User',
                    body: 'Failed to load current user, please call to contact center'
                });
        
                throw new Error(error);
            }
        },
        async recovery(email) {
            try {
                const response = await Recovery(email);

                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                }
            } catch (error) {
                toast({
                    variant: variant.error,
                    title: 'Recovery',
                    body: 'Failed to recovery account, please call to contact center or try later'
                });
        
                throw new Error(error);
            }
        },
        async recoveryValidKey(key){
            try {
                const response = await Validkey(key);

                if(response.status == httpStatusCode.OK){
                    return true;
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });

                    return false;
                }
            } catch (error) {
                toast({
                    variant: variant.error,
                    title: 'Recovery',
                    body: 'Failed to recovery account, please call to contact center or try later'
                });
        
                throw new Error(error);
            }
        },
        async recoveryChange(guid, model) {
            try {
                const response = await Change(guid, model);

                if(response.status == httpStatusCode.OK){
                    toast({
                        variant: variant.success,
                        title: response.data.title,
                        body:  response.data.message
                    });
                    return true;
                }else{
                    toast({
                        collection: true,
                        variant: variant.error,
                        title: response.data.title,
                        body:  response.data.messages
                    });
                }
                return false;
            } catch (error) {
                toast({
                    variant: variant.error,
                    title: 'Recovery',
                    body: 'Failed to recovery account, please call to contact center or try later'
                });
        
                throw new Error(error);
            }
        },
        updateTokes(accessToken, refreshToken){
            this.user.token = accessToken;
            this.user.refreshToken = refreshToken;
            localStorage.setItem(ACCOUNT_KEY, accessToken);
            localStorage.setItem(REFRESH_KEY, refreshToken);
        },
        sigOut() {
            localStorage.clear();
            this.$reset();
        }
    }
});